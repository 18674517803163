import { ApolloClient, ApolloClientOptions, ApolloProvider, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import React, { ReactNode } from 'react'
import { useAuth } from '../../hooks/auth.hook'

interface PropsInterface {
  children: ReactNode
}

const GraphQLProvider = ({ children }: PropsInterface): JSX.Element => {
  const { isAuthenticated, token } = useAuth()

  let clientConfig: ApolloClientOptions<NormalizedCacheObject> = {
    uri: process.env.REACT_APP_GRAPHQL_URL,
    cache: new InMemoryCache(),
  }

  if (isAuthenticated && token) {
    clientConfig = {
      ...clientConfig,
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  }

  const client = new ApolloClient(clientConfig)

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default GraphQLProvider
