import { ApolloError, gql, useLazyQuery, useMutation } from '@apollo/client'
import { Spinner, Button, InputField } from '@qesheq/qesheq-ui'
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { Alert, Checkbox, FormCard, PageHeader } from '../../components'
import { AlertInterface } from '../../components/alert/alert.component'
import { useInputState } from '../../hooks'
import { TagDTO } from '../../providers/graphql/dto'
import './tag-details.styles.css'

const tagFields = `
    id
    title
    dateCreated
    dateModified
    isDeleted
    isPublished
`
const TagQuery = gql`
  query Tag($id: String!) {
    tag(id: $id) {
     ${tagFields}
    }
  }
`

const CreateTagMutation = gql`
  mutation createTag($data: CreateTagInput!) {
    createTag(data: $data) {
      ${tagFields}
    }
  }
`
const UpdateTagMutation = gql`
  mutation updateTag($id: String!, $data: UpdateTagInput!) {
    updateTag(id: $id, data: $data) {
      ${tagFields}
    }
  }
`
const ArchiveTagMutation = gql`
  mutation archiveTags($ids: [ObjectId!]!) {
    archiveTags(ids: $ids)
  }
`

const TagDetailsContainer = (): JSX.Element => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [alerts, setAlerts] = useState<Array<AlertInterface>>([])

  const [isPublished, setIsPublished] = useState(false)

  const {
    value: title,
    setValue: setTitle,
    error: titleError,
    validate: validateTitle,
    onBlur: titleOnBlur,
  } = useInputState({ initialValue: '', validation: { required: true } })

  const [fetchTag, { loading, data: tagData }] = useLazyQuery(TagQuery, {
    variables: {
      id,
    },
    notifyOnNetworkStatusChange: true,
  })

  const [createTag] = useMutation(CreateTagMutation)
  const [updateTag] = useMutation(UpdateTagMutation)
  const [archiveTag] = useMutation(ArchiveTagMutation)

  const setTagValues = (tag: TagDTO): void => {
    setIsPublished(tag.isPublished)
    setTitle(tag.title)
  }

  useEffect(() => {
    if (id && id !== 'new') {
      fetchTag()
    }
  }, [id])

  useEffect(() => {
    if (tagData?.tag) {
      setTagValues(tagData?.tag)
    }
  }, [tagData])

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  const handleArchiveTag = async (): Promise<void> => {
    if (id) {
      await archiveTag({
        variables: {
          ids: [id],
        },
        onCompleted: () => {
          navigate('/tags')
        },
        onError: (err) => {
          setAlerts((prevAlerts) => [
            ...prevAlerts,
            { behavior: 'timed-and-controlled', type: 'error', description: err?.message, time: 5000 },
          ])
        },
      })
    }
  }

  const handleSaveForm = async (): Promise<void> => {
    let isFormValid = true
    isFormValid = validateTitle() && isFormValid

    if (!isLoading && isFormValid) {
      setIsLoading(true)
      const payload = {
        title,
        isPublished,
      }

      let updatedTag: TagDTO
      let submissionError: ApolloError | undefined

      if (id === 'new') {
        const { data } = await createTag({
          variables: {
            data: payload,
          },
          onError: (err) => {
            submissionError = err
          },
        })
        updatedTag = data?.createTag
      } else {
        const { data } = await updateTag({
          variables: {
            id,
            data: payload,
          },
          onError: (err) => {
            submissionError = err
          },
        })
        updatedTag = data?.updateTag
      }

      if (submissionError) {
        setAlerts((prevAlerts) => [
          ...prevAlerts,
          { behavior: 'timed-and-controlled', type: 'error', description: submissionError?.message, time: 5000 },
        ])
      } else if (updatedTag) {
        setAlerts((prevAlerts) => [...prevAlerts, { behavior: 'timed', type: 'success', description: 'تم حفظ المقال' }])
        if (id === 'new') {
          navigate(`/tag/${updatedTag.id}`, { replace: true })
        } else {
          setTagValues(updatedTag)
        }
      }

      setIsLoading(false)
    } else {
      setAlerts((prevAlerts) => [
        ...prevAlerts,
        {
          behavior: 'timed-and-controlled',
          type: 'error',
          title: 'راجع الحقول',
          description: `بعض الحقول غير مكتملة أو غير صالحة
        `,
        },
      ])
    }
  }

  return (
    <div className='tag-details'>
      <Alert alerts={alerts} updateAlerts={setAlerts} />
      <PageHeader title='كلمة دليلية'>
        {id !== 'new' && (
          <Button
            type='button'
            label='حذف'
            layout='solid'
            colorStyle='secondary'
            customClassName='page-header__button'
            isDisabled={isLoading}
            onClick={handleArchiveTag}
          />
        )}
        <Button
          type='link'
          label='خروج'
          layout='outline'
          href='/tags'
          colorStyle='secondary'
          customClassName='page-header__button'
          isDisabled={isLoading}
        />
        <Button
          type='button'
          label='حفط'
          layout='solid'
          customClassName='page-header__button'
          isDisabled={isLoading}
          onClick={handleSaveForm}
        />
      </PageHeader>
      <div className='tag-details__container'>
        {isLoading && (
          <div className='tag-details__spinner-overlay'>
            <Spinner layout='qesheq-boxed' />
          </div>
        )}
        <FormCard
          customClassName='tag-details__card tag-details__main-card'
          title='الرؤية'
          columns={2}
          rowsGap={2}
          columnsGap={2}>
          <Checkbox label='منشور' isChecked={isPublished} onChange={setIsPublished} reversed />
          <InputField
            value={title}
            onChange={setTitle}
            errorMessage={titleError}
            label='العنوان'
            onBlur={titleOnBlur}
          />
        </FormCard>
      </div>
    </div>
  )
}

export default TagDetailsContainer
