import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'

export type ProtectedRouteProps = {
  isAuthenticated: boolean
}

const PrivateRoute = ({ isAuthenticated }: ProtectedRouteProps): JSX.Element =>
  isAuthenticated ? <Outlet /> : <Navigate to='/login' />

export default PrivateRoute
