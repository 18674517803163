import React from 'react'
import { ApolloClient } from '@apollo/client'
import { UserInterface } from '../hooks/auth.hook'

export interface AuthContextInterface {
  checkingSession: boolean
  isAuthenticated: boolean
  token: string | null
  user: UserInterface | null
  login: (props: { token: string; expiresIn: number; persist?: boolean; user: UserInterface }) => void
  logout: (client: ApolloClient<object>) => void
  setUser: (user: UserInterface) => void
  setCheckingSession: (checkingSession: boolean) => void
}

const initialValue: AuthContextInterface = {
  checkingSession: false,
  isAuthenticated: false,
  token: null,
  user: null,
  login: () => {},
  logout: () => {},
  setUser: () => {},
  setCheckingSession: () => {},
}

export const AuthContext = React.createContext<AuthContextInterface>(initialValue)
