import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ArticlesContainer } from '../containers/articles'
import { CategoriesContainer } from '../containers/categories'
import { CategoryDetailsContainer } from '../containers/category-details'
import { WritersContainer } from '../containers/writers'
import { WriterDetailsContainer } from '../containers/writer-details'
import { DashboardContainer } from '../containers/dashboard'
import { PagesContainer } from '../containers/pages'
import { InquiriesContainer } from '../containers/inquiries'
import { InquiryDetailsContainer } from '../containers/inquiry-details'
import { UsersContainer } from '../containers/users'
import { UserDetailsContainer } from '../containers/user-details'
import { TagsContainer } from '../containers/tags'
import { TagDetailsContainer } from '../containers/tag-details'
import { ArticleDetailsContainer } from '../containers/article-details'
import { LoginContainer } from '../containers/login'
import { PageNotFoundContainer } from '../containers/page-not-found'
import { useAuth } from '../hooks/auth.hook'
import { AuthPage } from '../layout/auth-page'
import { MiniHeader } from '../layout/mini-header'
import { SidePanel } from '../layout/side-panel'
import { PrivateRoute } from './private-route'
import { PublicRoute } from './public-route'
import { PageDetailsContainer } from '../containers/page-details'

const MainRouter = (): JSX.Element => {
  const { isAuthenticated } = useAuth()

  return (
    <Routes>
      <Route element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
        <Route element={<SidePanel />}>
          <Route element={<MiniHeader />}>
            <Route path='/dashboard' element={<DashboardContainer />} />
            <Route path='/articles' element={<ArticlesContainer />} />
            <Route path='/article/:id' element={<ArticleDetailsContainer />} />
            <Route path='/categories' element={<CategoriesContainer />} />
            <Route path='/category/:id' element={<CategoryDetailsContainer />} />
            <Route path='/writers' element={<WritersContainer />} />
            <Route path='/writer/:id' element={<WriterDetailsContainer />} />
            <Route path='/pages' element={<PagesContainer />} />
            <Route path='/page/:id' element={<PageDetailsContainer />} />
            <Route path='/tags' element={<TagsContainer />} />
            <Route path='/tag/:id' element={<TagDetailsContainer />} />
            <Route path='/inquiries' element={<InquiriesContainer />} />
            <Route path='/inquiry/:id' element={<InquiryDetailsContainer />} />
            <Route path='/users' element={<UsersContainer />} />
            <Route path='/user/:id' element={<UserDetailsContainer />} />
            <Route path='/' element={<DashboardContainer />} />
          </Route>
        </Route>
      </Route>
      <Route element={<PublicRoute isAuthenticated={isAuthenticated} />}>
        <Route element={<AuthPage />}>
          <Route path='/login' element={<LoginContainer />} />
        </Route>
      </Route>

      <Route path='*' element={<PageNotFoundContainer />} />
    </Routes>
  )
}
export default MainRouter
