import { ApolloError, gql, useLazyQuery, useMutation } from '@apollo/client'
import { RepeatIcon, Descendant, QEditor, serializeToText, Spinner, Button, InputField } from '@qesheq/qesheq-ui'
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { Alert, Checkbox, FormCard, ImageUploader, PageHeader } from '../../components'
import { AlertInterface } from '../../components/alert/alert.component'
import { useInputState } from '../../hooks'
import { WriterDTO } from '../../providers/graphql/dto'
import { generateUrlKey } from '../../utils'
import './writer-details.styles.css'

const writerFields = `
    id
    fullname
    urlKey
    image
    coverImage
    bio
    facebook
    twitter
    website
    dateCreated
    dateModified
    isDeleted
    isPublished
`
const WriterQuery = gql`
  query Writer($id: String!) {
    writer(id: $id) {
     ${writerFields}
    }
  }
`

const CreateWriterMutation = gql`
  mutation createWriter($data: CreateWriterInput!) {
    createWriter(data: $data) {
      ${writerFields}
    }
  }
`
const UpdateWriterMutation = gql`
  mutation updateWriter($id: String!, $data: UpdateWriterInput!) {
    updateWriter(id: $id, data: $data) {
      ${writerFields}
    }
  }
`
const ArchiveWriterMutation = gql`
  mutation archiveWriters($ids: [ObjectId!]!) {
    archiveWriters(ids: $ids)
  }
`

const WriterDetailsContainer = (): JSX.Element => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [alerts, setAlerts] = useState<Array<AlertInterface>>([])

  const [isPublished, setIsPublished] = useState(false)

  const {
    value: fullname,
    setValue: setFullname,
    error: fullnameError,
    validate: validateFullname,
    onBlur: fullnameOnBlur,
  } = useInputState({ initialValue: '', validation: { required: true } })

  const {
    value: urlKey,
    setValue: setUrlKey,
    error: urlKeyError,
    validate: validateUrlKey,
    onBlur: urlKeyOnBlur,
  } = useInputState({ initialValue: '', validation: { required: true } })

  const {
    value: facebook,
    setValue: setFacebook,
    error: facebookError,
    validate: validateFacebook,
    onBlur: facebookOnBlur,
  } = useInputState<string | undefined>({ initialValue: '', validation: { url: true } })

  const {
    value: twitter,
    setValue: setTwitter,
    error: twitterError,
    validate: validateTwitter,
    onBlur: twitterOnBlur,
  } = useInputState<string | undefined>({ initialValue: undefined, validation: { url: true } })

  const {
    value: website,
    setValue: setWebsite,
    error: websiteError,
    validate: validateWebsite,
    onBlur: websiteOnBlur,
  } = useInputState<string | undefined>({ initialValue: '', validation: { url: true } })

  const {
    value: bio,
    setValue: setBio,
    validate: validateBio,
  } = useInputState<Descendant[] | undefined>({
    initialValue: undefined,
    validation: {},
  })

  const {
    value: image,
    setValue: setImage,
    validate: validateImage,
  } = useInputState<string | null>({ initialValue: null, validation: { required: true } })

  const {
    value: coverImage,
    setValue: setCoverImage,
    validate: validateCoverImage,
  } = useInputState<string | null>({ initialValue: null, validation: { required: true } })

  const [fetchWriter, { loading, data: writerData }] = useLazyQuery(WriterQuery, {
    variables: {
      id,
    },
    notifyOnNetworkStatusChange: true,
  })

  const [createWriter] = useMutation(CreateWriterMutation)
  const [updateWriter] = useMutation(UpdateWriterMutation)
  const [archiveWriter] = useMutation(ArchiveWriterMutation)

  const setWriterValues = (writer: WriterDTO): void => {
    setIsPublished(writer.isPublished)
    setFullname(writer.fullname)
    setUrlKey(writer.urlKey)
    setBio(writer.bio)
    setFacebook(writer.facebook)
    setTwitter(writer.twitter)
    setWebsite(writer.website)
    setImage(writer.image || null)
    setCoverImage(writer.coverImage || null)
  }

  useEffect(() => {
    if (id && id !== 'new') {
      fetchWriter()
    }
  }, [id])

  useEffect(() => {
    if (writerData?.writer) {
      setWriterValues(writerData?.writer)
    }
  }, [writerData])

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  const handleUrlKeyClick = (): void => {
    setUrlKey(generateUrlKey(fullname))
  }

  const handleArchiveWriter = async (): Promise<void> => {
    if (id) {
      await archiveWriter({
        variables: {
          ids: [id],
        },
        onCompleted: () => {
          navigate('/writers')
        },
        onError: (err) => {
          setAlerts((prevAlerts) => [
            ...prevAlerts,
            { behavior: 'timed-and-controlled', type: 'error', description: err?.message, time: 5000 },
          ])
        },
      })
    }
  }

  const handleSaveForm = async (): Promise<void> => {
    let isFormValid = true
    isFormValid = validateFullname() && isFormValid
    isFormValid = validateBio() && isFormValid
    isFormValid = validateUrlKey() && isFormValid
    isFormValid = validateImage() && isFormValid
    isFormValid = validateCoverImage() && isFormValid
    isFormValid = validateFacebook() && isFormValid
    isFormValid = validateTwitter() && isFormValid
    isFormValid = validateWebsite() && isFormValid

    if (!isLoading && isFormValid) {
      setIsLoading(true)
      const payload = {
        fullname,
        urlKey,
        bio,
        rawBio: serializeToText(bio || null) || undefined,
        isPublished,
        image: image || undefined,
        coverImage: coverImage || undefined,
        facebook: facebook || undefined,
        twitter: twitter || undefined,
        website: website || undefined,
      }

      let updatedWriter: WriterDTO
      let submissionError: ApolloError | undefined

      if (id === 'new') {
        const { data } = await createWriter({
          variables: {
            data: payload,
          },
          onError: (err) => {
            submissionError = err
          },
        })
        updatedWriter = data?.createWriter
      } else {
        const { data } = await updateWriter({
          variables: {
            id,
            data: payload,
          },
          onError: (err) => {
            submissionError = err
          },
        })
        updatedWriter = data?.updateWriter
      }

      if (submissionError) {
        setAlerts((prevAlerts) => [
          ...prevAlerts,
          { behavior: 'timed-and-controlled', type: 'error', description: submissionError?.message, time: 5000 },
        ])
      } else if (updatedWriter) {
        setAlerts((prevAlerts) => [...prevAlerts, { behavior: 'timed', type: 'success', description: 'تم حفظ المقال' }])
        if (id === 'new') {
          navigate(`/writer/${updatedWriter.id}`, { replace: true })
        } else {
          setWriterValues(updatedWriter)
        }
      }

      setIsLoading(false)
    } else {
      setAlerts((prevAlerts) => [
        ...prevAlerts,
        {
          behavior: 'timed-and-controlled',
          type: 'error',
          title: 'راجع الحقول',
          description: `بعض الحقول غير مكتملة أو غير صالحة
        `,
        },
      ])
    }
  }

  return (
    <div className='writer-details'>
      <Alert alerts={alerts} updateAlerts={setAlerts} />
      <PageHeader title='كاتب'>
        {id !== 'new' && (
          <Button
            type='button'
            label='حذف'
            layout='solid'
            colorStyle='secondary'
            customClassName='page-header__button'
            isDisabled={isLoading}
            onClick={handleArchiveWriter}
          />
        )}
        <Button
          type='link'
          label='خروج'
          layout='outline'
          href='/writers'
          colorStyle='secondary'
          customClassName='page-header__button'
          isDisabled={isLoading}
        />
        <Button
          type='button'
          label='حفط'
          layout='solid'
          customClassName='page-header__button'
          isDisabled={isLoading}
          onClick={handleSaveForm}
        />
      </PageHeader>
      <div className='writer-details__container'>
        {isLoading && (
          <div className='writer-details__spinner-overlay'>
            <Spinner layout='qesheq-boxed' />
          </div>
        )}
        <FormCard
          customClassName='writer-details__card writer-details__visibility-card'
          title='الرؤية'
          columns={2}
          rowsGap={2}
          columnsGap={2}>
          <Checkbox label='منشور' isChecked={isPublished} onChange={setIsPublished} reversed />
        </FormCard>
        <FormCard
          customClassName='writer-details__card writer-details__media-card'
          title='الصور'
          columns={2}
          columnsGap={2}>
          <ImageUploader label='الصورة الشخصية' setImage={setImage} image={image} section='writer' location='avatar' />
          <ImageUploader
            label='صورة الغلاف'
            setImage={setCoverImage}
            image={coverImage}
            section='writer'
            location='cover'
          />
        </FormCard>
        <FormCard
          customClassName='writer-details__card writer-details__info-card'
          title='المعلومات'
          columns={2}
          columnsGap={4}
          rowsGap={4}>
          <InputField
            value={fullname}
            onChange={setFullname}
            errorMessage={fullnameError}
            label='الأسم'
            onBlur={fullnameOnBlur}
          />
          <InputField
            value={urlKey}
            onChange={setUrlKey}
            errorMessage={urlKeyError}
            label='مفتاح العنوان'
            onBlur={urlKeyOnBlur}
            icon={<RepeatIcon />}
            iconOnClick={handleUrlKeyClick}
          />
          <InputField
            value={facebook}
            onChange={setFacebook}
            errorMessage={facebookError}
            label='رابط فيسبوك'
            onBlur={facebookOnBlur}
          />
          <InputField
            value={twitter}
            onChange={setTwitter}
            errorMessage={twitterError}
            label='رابط تويتر'
            onBlur={twitterOnBlur}
          />
          <InputField
            value={website}
            onChange={setWebsite}
            errorMessage={websiteError}
            label='رابط الموقع'
            onBlur={websiteOnBlur}
          />
        </FormCard>
        <FormCard customClassName='writer-details__card writer-details__description-card' title='النص'>
          <QEditor
            customClassName='writer-details__description-editor'
            layout='default'
            imageRole='writer:description'
            placeholder='اكتب هنا..'
            value={bio}
            setValue={setBio}
          />
        </FormCard>
      </div>
    </div>
  )
}

export default WriterDetailsContainer
