import React from 'react'
import './arrow.styles.css'

interface ArrowIconProps {
  direction: 'left' | 'right' | 'top' | 'bottom'
  role?: 'button'
  onClick?: () => void
}

const ArrowIcon = ({ direction, onClick, role }: ArrowIconProps): JSX.Element => {
  const className = `icon-arrow icon-arrow--${direction} icon-arrow--${role}`
  switch (role) {
    case 'button':
      return <button className={className} onClick={onClick} onKeyPress={onClick} type='button' />
    default:
      return <div className={className} />
  }
}

ArrowIcon.defaultProps = {
  role: 'none',
}

export default ArrowIcon
