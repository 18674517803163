import { ApolloError, gql, useLazyQuery, useMutation } from '@apollo/client'
import { RepeatIcon, Descendant, QEditor, serializeToText, Spinner, Button, InputField } from '@qesheq/qesheq-ui'
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { Alert, Checkbox, FormCard, ImageUploader, PageHeader } from '../../components'
import { AlertInterface } from '../../components/alert/alert.component'
import { useInputState } from '../../hooks'
import { CategoryDTO } from '../../providers/graphql/dto'
import { generateUrlKey } from '../../utils'
import './category-details.styles.css'

const categoryFields = `
    id
    title
    urlKey
    image
    bannerImage
    smallDescription
    description
    dateCreated
    dateModified
    isDeleted
    isPublished
    isFeatured
    inHeader
`
const CategoryQuery = gql`
  query Category($id: String!) {
    category(id: $id) {
     ${categoryFields}
    }
  }
`

const CreateCategoryMutation = gql`
  mutation createCategory($data: CreateCategoryInput!) {
    createCategory(data: $data) {
      ${categoryFields}
    }
  }
`
const UpdateCategoryMutation = gql`
  mutation updateCategory($id: String!, $data: UpdateCategoryInput!) {
    updateCategory(id: $id, data: $data) {
      ${categoryFields}
    }
  }
`
const ArchiveCategoryMutation = gql`
  mutation archiveCategories($ids: [ObjectId!]!) {
    archiveCategories(ids: $ids)
  }
`

const CategoryDetailsContainer = (): JSX.Element => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [alerts, setAlerts] = useState<Array<AlertInterface>>([])

  const [isPublished, setIsPublished] = useState(false)
  const [isFeatured, setIsFeatured] = useState<boolean>(false)
  const [inHeader, setInHeader] = useState<boolean>(false)

  const {
    value: title,
    setValue: setTitle,
    error: titleError,
    validate: validateTitle,
    onBlur: titleOnBlur,
  } = useInputState({ initialValue: '', validation: { required: true } })

  const {
    value: urlKey,
    setValue: setUrlKey,
    error: urlKeyError,
    validate: validateUrlKey,
    onBlur: urlKeyOnBlur,
  } = useInputState({ initialValue: '', validation: { required: true } })

  const {
    value: smallDescription,
    setValue: setSmallDescription,
    error: smallDescriptionError,
    validate: validateSmallDescription,
    onBlur: smallDescriptionOnBlur,
  } = useInputState<string | undefined>({ initialValue: '', validation: {} })

  const {
    value: description,
    setValue: setDescription,
    validate: validateDescription,
  } = useInputState<Descendant[] | undefined>({
    initialValue: undefined,
    validation: {},
  })

  const {
    value: image,
    setValue: setImage,
    validate: validateImage,
  } = useInputState<string | null>({ initialValue: null, validation: { required: true } })

  const {
    value: bannerImage,
    setValue: setBannerImage,
    validate: validateBannerImage,
  } = useInputState<string | null>({ initialValue: null, validation: { required: true } })

  const [fetchCategory, { loading, error, data: categoryData }] = useLazyQuery(CategoryQuery, {
    variables: {
      id,
    },
    notifyOnNetworkStatusChange: true,
  })

  const [createCategory] = useMutation(CreateCategoryMutation)
  const [updateCategory] = useMutation(UpdateCategoryMutation)
  const [archiveCategory] = useMutation(ArchiveCategoryMutation)

  const setCategoryValues = (category: CategoryDTO): void => {
    setIsFeatured(!!category.isFeatured)
    setIsPublished(category.isPublished)
    setInHeader(!!category.inHeader)
    setTitle(category.title)
    setUrlKey(category.urlKey)
    setSmallDescription(category.smallDescription)
    setDescription(category.description)
    setImage(category.image || null)
    setBannerImage(category.bannerImage || null)
  }

  useEffect(() => {
    if (id && id !== 'new') {
      fetchCategory()
    }
  }, [id])

  useEffect(() => {
    if (categoryData?.category) {
      setCategoryValues(categoryData?.category)
    }
  }, [categoryData])

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  const handleUrlKeyClick = (): void => {
    setUrlKey(generateUrlKey(title))
  }

  const handleArchiveCategory = async (): Promise<void> => {
    if (id) {
      await archiveCategory({
        variables: {
          ids: [id],
        },
        onCompleted: () => {
          navigate('/categories')
        },
        onError: (err) => {
          setAlerts((prevAlerts) => [
            ...prevAlerts,
            { behavior: 'timed-and-controlled', type: 'error', description: err?.message, time: 5000 },
          ])
        },
      })
    }
  }

  const handleSaveForm = async (): Promise<void> => {
    let isFormValid = true
    isFormValid = validateTitle() && isFormValid
    isFormValid = validateSmallDescription() && isFormValid
    isFormValid = validateDescription() && isFormValid
    isFormValid = validateUrlKey() && isFormValid
    isFormValid = validateImage() && isFormValid
    isFormValid = validateBannerImage() && isFormValid

    if (!isLoading && isFormValid) {
      setIsLoading(true)
      const payload = {
        title,
        urlKey,
        description: description || undefined,
        rawDescription: serializeToText(description || null) || undefined,
        smallDescription: smallDescription || undefined,
        isPublished,
        isFeatured,
        inHeader,
        image: image || undefined,
        bannerImage: bannerImage || undefined,
      }

      let updatedCategory: CategoryDTO
      let submissionError: ApolloError | undefined

      if (id === 'new') {
        const { data } = await createCategory({
          variables: {
            data: payload,
          },
          onError: (err) => {
            submissionError = err
          },
        })
        updatedCategory = data?.createCategory
      } else {
        const { data } = await updateCategory({
          variables: {
            id,
            data: payload,
          },
          onError: (err) => {
            submissionError = err
          },
        })
        updatedCategory = data?.updateCategory
      }

      if (submissionError) {
        setAlerts((prevAlerts) => [
          ...prevAlerts,
          { behavior: 'timed-and-controlled', type: 'error', description: submissionError?.message, time: 5000 },
        ])
      } else if (updatedCategory) {
        setAlerts((prevAlerts) => [...prevAlerts, { behavior: 'timed', type: 'success', description: 'تم حفظ المقال' }])
        if (id === 'new') {
          navigate(`/category/${updatedCategory.id}`, { replace: true })
        } else {
          setCategoryValues(updatedCategory)
        }
      }

      setIsLoading(false)
    } else {
      setAlerts((prevAlerts) => [
        ...prevAlerts,
        {
          behavior: 'timed-and-controlled',
          type: 'error',
          title: 'راجع الحقول',
          description: `بعض الحقول غير مكتملة أو غير صالحة
        `,
        },
      ])
    }
  }

  return (
    <div className='category-details'>
      <Alert alerts={alerts} updateAlerts={setAlerts} />
      <PageHeader title='موضوع'>
        {id !== 'new' && (
          <Button
            type='button'
            label='حذف'
            layout='solid'
            colorStyle='secondary'
            customClassName='page-header__button'
            isDisabled={isLoading}
            onClick={handleArchiveCategory}
          />
        )}
        <Button
          type='link'
          label='خروج'
          layout='outline'
          href='/categories'
          colorStyle='secondary'
          customClassName='page-header__button'
          isDisabled={isLoading}
        />
        <Button
          type='button'
          label='حفط'
          layout='solid'
          customClassName='page-header__button'
          isDisabled={isLoading}
          onClick={handleSaveForm}
        />
      </PageHeader>
      <div className='category-details__container'>
        {isLoading && (
          <div className='category-details__spinner-overlay'>
            <Spinner layout='qesheq-boxed' />
          </div>
        )}
        <FormCard
          customClassName='category-details__card category-details__visibility-card'
          title='الرؤية'
          columns={2}
          rowsGap={2}
          columnsGap={2}>
          <Checkbox label='منشور' isChecked={isPublished} onChange={setIsPublished} reversed />
          <Checkbox label='متميز' isChecked={isFeatured} onChange={setIsFeatured} reversed />
          <Checkbox label='ظاهر' isChecked={inHeader} onChange={setInHeader} reversed />
        </FormCard>
        <FormCard
          customClassName='category-details__card category-details__media-card'
          title='الصور'
          columns={2}
          columnsGap={2}>
          <ImageUploader label='صورة القائمة' setImage={setImage} image={image} section='category' location='listing' />
          <ImageUploader
            label='صورة اللافتة'
            setImage={setBannerImage}
            image={bannerImage}
            section='category'
            location='banner'
          />
        </FormCard>
        <FormCard
          customClassName='category-details__card category-details__info-card'
          title='معلومات'
          columns={2}
          columnsGap={4}
          rowsGap={4}>
          <InputField
            value={title}
            onChange={setTitle}
            errorMessage={titleError}
            label='العنوان'
            onBlur={titleOnBlur}
          />
          <InputField
            value={urlKey}
            onChange={setUrlKey}
            errorMessage={urlKeyError}
            label='مفتاح العنوان'
            onBlur={urlKeyOnBlur}
            icon={<RepeatIcon />}
            iconOnClick={handleUrlKeyClick}
          />
          <InputField
            type='text'
            value={smallDescription}
            onChange={setSmallDescription}
            errorMessage={smallDescriptionError}
            label='ملخص'
            onBlur={smallDescriptionOnBlur}
          />
        </FormCard>
        <FormCard customClassName='category-details__card category-details__description-card' title='النص'>
          <QEditor
            customClassName='category-details__description-editor'
            layout='default'
            imageRole='category:description'
            placeholder='اكتب هنا..'
            value={description}
            setValue={setDescription}
          />
        </FormCard>
      </div>
    </div>
  )
}

export default CategoryDetailsContainer
