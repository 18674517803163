import { ApolloError, gql, useLazyQuery, useMutation } from '@apollo/client'
import { Spinner, Button, InputField } from '@qesheq/qesheq-ui'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { Alert, FormCard, PageHeader } from '../../components'
import { AlertInterface } from '../../components/alert/alert.component'
import { useInputState } from '../../hooks'
import { UserDTO } from '../../providers/graphql/dto'
import './user-details.styles.css'

const userFields = `
    id
    email
    dateCreated
    dateModified
    isDeleted
`
const UserQuery = gql`
  query userById($id: String!) {
    userById(id: $id) {
     ${userFields}
    }
  }
`

const RegisterUserMutation = gql`
  mutation register($email: String!, $password: String!) {
    register(email: $email, password: $password) {
      ${userFields}
    }
  }
`
const ArchiveUserMutation = gql`
  mutation archiveUsers($ids: [ObjectId!]!) {
    archiveUsers(ids: $ids)
  }
`

const UserDetailsContainer = (): JSX.Element => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [alerts, setAlerts] = useState<Array<AlertInterface>>([])

  const {
    value: email,
    setValue: setEmail,
    error: emailError,
    validate: validateEmail,
    onBlur: emailOnBlur,
  } = useInputState({ initialValue: '', validation: { required: true } })

  const {
    value: password,
    setValue: setPassword,
    error: passwordError,
    validate: validatePassword,
    onBlur: passwordOnBlur,
  } = useInputState({ initialValue: '', validation: { required: true } })

  const [fetchUser, { loading, data: userData }] = useLazyQuery(UserQuery, {
    variables: {
      id,
    },
    notifyOnNetworkStatusChange: true,
  })

  const [register] = useMutation(RegisterUserMutation)
  const [archiveUser] = useMutation(ArchiveUserMutation)

  const setUserValues = (user: UserDTO): void => {
    setEmail(user.email)
  }

  useEffect(() => {
    if (id && id !== 'new') {
      fetchUser()
    }
  }, [id])

  useEffect(() => {
    if (userData?.userById) {
      setUserValues(userData?.userById)
    }
  }, [userData])

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  const handleArchiveUser = async (): Promise<void> => {
    if (id) {
      await archiveUser({
        variables: {
          ids: [id],
        },
        onCompleted: () => {
          navigate('/users')
        },
        onError: (err) => {
          setAlerts((prevAlerts) => [
            ...prevAlerts,
            { behavior: 'timed-and-controlled', type: 'error', description: err?.message, time: 5000 },
          ])
        },
      })
    }
  }

  const handleSaveForm = async (): Promise<void> => {
    let isFormValid = true
    isFormValid = validateEmail() && isFormValid
    isFormValid = validatePassword() && isFormValid

    if (!isLoading && isFormValid) {
      setIsLoading(true)

      if (id === 'new') {
        const { data } = await register({
          variables: {
            email,
            password,
          },
          onError: (submissionError: ApolloError | undefined) => {
            setAlerts((prevAlerts) => [
              ...prevAlerts,
              { behavior: 'timed-and-controlled', type: 'error', description: submissionError?.message, time: 5000 },
            ])
          },
        })
        if (data?.register) {
          setAlerts((prevAlerts) => [
            ...prevAlerts,
            { behavior: 'timed', type: 'success', description: 'تم حفظ المقال' },
          ])

          navigate(`/user/${data.register.id}`, { replace: true })
        }
      }

      setIsLoading(false)
    } else {
      setAlerts((prevAlerts) => [
        ...prevAlerts,
        {
          behavior: 'timed-and-controlled',
          type: 'error',
          title: 'راجع الحقول',
          description: `بعض الحقول غير مكتملة أو غير صالحة
        `,
        },
      ])
    }
  }

  const isNewUser = useMemo(() => id === 'new', [id])
  return (
    <div className='user-details'>
      <Alert alerts={alerts} updateAlerts={setAlerts} />
      <PageHeader title='كلمة دليلية'>
        {!isNewUser && (
          <Button
            type='button'
            label='حذف'
            layout='solid'
            colorStyle='secondary'
            customClassName='page-header__button'
            isDisabled={isLoading}
            onClick={handleArchiveUser}
          />
        )}
        <Button
          type='link'
          label='خروج'
          layout='outline'
          href='/users'
          colorStyle='secondary'
          customClassName='page-header__button'
          isDisabled={isLoading}
        />
        {isNewUser && (
          <Button
            type='button'
            label='حفط'
            layout='solid'
            customClassName='page-header__button'
            isDisabled={isLoading}
            onClick={handleSaveForm}
          />
        )}
      </PageHeader>
      <div className='user-details__container'>
        {isLoading && (
          <div className='user-details__spinner-overlay'>
            <Spinner layout='qesheq-boxed' />
          </div>
        )}
        <FormCard
          customClassName='user-details__card user-details__main-card'
          title='معلومات'
          columns={2}
          rowsGap={2}
          columnsGap={2}>
          <InputField
            value={email}
            onChange={setEmail}
            errorMessage={emailError}
            label='البريد الالكتروني'
            onBlur={emailOnBlur}
            isDisabled={!isNewUser}
          />
          {isNewUser && (
            <InputField
              value={password}
              onChange={setPassword}
              errorMessage={passwordError}
              label='كلمة السر'
              onBlur={passwordOnBlur}
              type='password'
            />
          )}
        </FormCard>
      </div>
    </div>
  )
}

export default UserDetailsContainer
