import { ImagesIcon, Button, TrashIcon, PencilSimpleIcon, Spinner } from '@qesheq/qesheq-ui'
import React, { ChangeEvent, useMemo, useRef, useState } from 'react'
import { v4 as uuid } from 'uuid'

import './image-uploader.styles.css'
import { useAuth } from '../../hooks/auth.hook'

// type ImageRole =
//   | 'article:main'
//   | 'category:listing'
//   | 'category:banner'
//   | 'writer:avatar'
//   | 'writer:banner'
//   | 'page:main'

interface ImageUploaderProps {
  label?: string
  customClassName?: string
  image?: string | null
  setImage: (filename: string | null) => void
  section: string
  location: string
}

const ImageUploader = (props: ImageUploaderProps): JSX.Element => {
  const { token } = useAuth()
  const INPUT_ID = uuid()

  const { label, customClassName, image, setImage, section, location } = props

  const [isLoading, setIsLoading] = useState(false)

  const explorerReference = useRef<HTMLInputElement>(null)

  const handleOnImageChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (event?.target?.files?.length) {
      const data = new FormData()
      // data.append('role', `${section}:${location}`)
      const role = `${section}:${location}`
      data.append('image', event.target.files[0])

      setIsLoading(true)

      const result = await fetch(`${process.env.REACT_APP_API_URL}images?role=${role}`, {
        method: 'POST',
        body: data,
        headers: token
          ? {
              authorization: `Bearer ${token}`,
            }
          : undefined,
      })
      const uploadedFile = await result.json()

      setIsLoading(false)
      const imageName = uploadedFile.key.split('/')[uploadedFile.key.split('/').length - 1]
      setImage(`${imageName}`)
    }
  }

  const handleDeleteImage = (): void => {
    setImage(null)
  }
  const handleEditImage = (): void => {
    explorerReference?.current?.click()
  }

  const imagePath = useMemo(
    () => (image ? `${process.env.REACT_APP_MEDIA_URL}raw/${section}/${image}` : null),
    [image, section]
  )

  return (
    <div className={`image-uploader ${customClassName || ''}`}>
      {label && <span className='image-uploader__label'>{label}</span>}
      <div className='image-uploader__container'>
        <label
          htmlFor={INPUT_ID}
          className={`image-uploader__explorer ${imagePath || isLoading ? 'image-uploader__explorer--disabled' : ''}`}>
          <input
            type='file'
            id={INPUT_ID}
            onChange={handleOnImageChange}
            className='image-uploader__input'
            ref={explorerReference}
          />
          {!imagePath && !isLoading && <ImagesIcon className='image-uploader__no-image-icon' />}
        </label>
        {isLoading && (
          <Spinner
            layout='qesheq-default'
            customClassName={`image-uploader__spinner ${imagePath ? 'image-uploader__spinner--overlay' : ''}`}
          />
        )}
        {imagePath && <img alt={label} className='image-uploader__image' src={imagePath} />}
        {imagePath && !isLoading && (
          <div className='image-uploader__actions'>
            <Button
              customClassName='image-uploader__action-button'
              label='edit'
              onClick={handleEditImage}
              layout='icon-solid'
              colorStyle='primary'
              type='button'
              icon={<PencilSimpleIcon />}
            />
            <Button
              customClassName='image-uploader__action-button'
              label='delete'
              onClick={handleDeleteImage}
              layout='icon-solid'
              colorStyle='secondary'
              type='button'
              icon={<TrashIcon />}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default ImageUploader
