import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { Spinner, Button, InputField, TextAreaField } from '@qesheq/qesheq-ui'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Alert, FormCard, PageHeader } from '../../components'
import { AlertInterface } from '../../components/alert/alert.component'
import { InquiryDTO } from '../../providers/graphql/dto'
import { InquiryTypes } from '../../types/inquiry.types'
import './inquiry-details.styles.css'

const inquiryFields = `
    id
    type
    name
    company
    email
    phone
    budget
    country
    duration
    gender
    message
    dateCreated
`
const InquiryQuery = gql`
  query Inquiry($id: String!) {
    inquiry(id: $id) {
     ${inquiryFields}
    }
  }
`
const ArchiveInquiryMutation = gql`
  mutation archiveInquiries($ids: [ObjectId!]!) {
    archiveInquiries(ids: $ids)
  }
`

const InquiryDetailsContainer = (): JSX.Element => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [alerts, setAlerts] = useState<Array<AlertInterface>>([])

  const [name, setName] = useState<string>('')
  const [type, setType] = useState<InquiryTypes | null>(null)
  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string | undefined>()
  const [company, setCompany] = useState<string | undefined>()
  const [budget, setBudget] = useState<string | undefined>()
  const [country, setCountry] = useState<string | undefined>()
  const [duration, setDuration] = useState<string | undefined>()
  const [gender, setGender] = useState<string | undefined>()
  const [message, setMessage] = useState<string | undefined>()
  const [dateCreated, setDateCreated] = useState<string>()

  const [fetchInquiry, { loading, data: inquiryData }] = useLazyQuery(InquiryQuery, {
    variables: {
      id,
    },
    notifyOnNetworkStatusChange: true,
  })
  const [archiveInquiry] = useMutation(ArchiveInquiryMutation)

  const setInquiryValues = (inquiry: InquiryDTO): void => {
    setName(inquiry.name)
    setType(inquiry.type)
    setEmail(inquiry.email)
    setPhone(inquiry.phone)
    setCompany(inquiry.company)
    setDuration(inquiry.duration)
    setGender(inquiry.gender)
    setBudget(inquiry.budget)
    setCountry(inquiry.country)
    setMessage(inquiry.message)
    setDateCreated((inquiry.dateCreated || '').split('T')[0])
  }

  const handleArchiveInquiry = async (): Promise<void> => {
    if (id) {
      await archiveInquiry({
        variables: {
          ids: [id],
        },
        onCompleted: () => {
          navigate('/inquiries')
        },
        onError: (err) => {
          setAlerts((prevAlerts) => [
            ...prevAlerts,
            { behavior: 'timed-and-controlled', type: 'error', description: err?.message, time: 5000 },
          ])
        },
      })
    }
  }

  useEffect(() => {
    fetchInquiry()
  }, [id])

  useEffect(() => {
    if (inquiryData?.inquiry) {
      setInquiryValues(inquiryData?.inquiry)
    }
  }, [inquiryData])

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  const inquiryTypeLabel = useMemo(() => {
    switch (type) {
      case 'advertise':
        return 'إعلانات'
      case 'become-a-writer':
        return 'أصبح كاتبا'
      case 'contact':
        return 'اتصل بنا'
      default:
        return ''
    }
  }, [type])
  return (
    <div className='inquiry-details'>
      <Alert alerts={alerts} updateAlerts={setAlerts} />
      <PageHeader title='طلب'>
        <Button
          type='button'
          label='حذف'
          layout='solid'
          colorStyle='secondary'
          customClassName='page-header__button'
          isDisabled={isLoading}
          onClick={handleArchiveInquiry}
        />
        <Button
          type='link'
          label='خروج'
          layout='outline'
          href='/inquiries'
          colorStyle='secondary'
          customClassName='page-header__button'
          isDisabled={isLoading}
        />
      </PageHeader>
      <div className='inquiry-details__container'>
        {isLoading && (
          <div className='inquiry-details__spinner-overlay'>
            <Spinner layout='qesheq-boxed' />
          </div>
        )}
        <FormCard
          customClassName='inquiry-details__card inquiry-details__main-card'
          columns={2}
          rowsGap={2}
          columnsGap={2}>
          <InputField value={inquiryTypeLabel} label='نوع الطلب' isDisabled />
          <InputField value={name} label='الإسم' isDisabled />
          <InputField value={dateCreated} label='تاريخ الطلب' isDisabled />
          <InputField value={email} label='البريد الإلكتروني' isDisabled />
          {(type === 'contact' || type === 'advertise') && <InputField value={phone} label='رقم الهاتف' isDisabled />}
          {type === 'advertise' && (
            <>
              <InputField value={company} label='إسم الشركة' isDisabled />
              <InputField value={budget} label='الميزانية' isDisabled />
              <InputField value={duration} label='الفترة الزمنية' isDisabled />
            </>
          )}
          {type === 'become-a-writer' && (
            <>
              <InputField value={gender} label='الجنس' isDisabled />
              <InputField value={country} label='بلد الاقامة' isDisabled />
            </>
          )}
          <TextAreaField value={message} label='الرسالة' customClassName='inquiry-details__message-field' isDisabled />
        </FormCard>
      </div>
    </div>
  )
}

export default InquiryDetailsContainer
