import React, { useMemo } from 'react'

import { PaginatedList } from '../../providers/graphql/types/paginaton.types'
import ArrowIcon from '../icons/arrow/arrow.component'

import './pagination.styles.css'

interface PaginationProps {
  paginatedList: PaginatedList<any>
  onPageChange: (page: number) => void
  onLimitChange: (limit: number) => void
  page: number
}

function Pagination(props: PaginationProps): JSX.Element {
  const { paginatedList, page, onPageChange, onLimitChange } = props

  const hasPreviousPage = useMemo(() => paginatedList.skip > 0 && page > 0, [paginatedList.skip, page])

  const hasNextPage = useMemo(
    () => paginatedList.skip + paginatedList.limit < paginatedList.total,
    [paginatedList.skip, paginatedList.limit, paginatedList.total]
  )

  const handlePreviousPageClick = (): void => {
    if (hasPreviousPage) {
      onPageChange(page - 1)
    }
  }

  const handleNextPageClick = (): void => {
    if (hasNextPage) {
      onPageChange(page + 1)
    }
  }

  const handleLimitChange = (limit: number): void => {
    onLimitChange(limit)
  }

  return (
    <div className='pagination'>
      <div className='pagination__view-per-page'>عرض {paginatedList.limit} لكل صفحة</div>
      <div className='pagination__navigation'>
        <ArrowIcon onClick={handlePreviousPageClick} direction='right' role='button' />
        <div className='pagination__navigation-page'>{page + 1}</div>
        <ArrowIcon onClick={handleNextPageClick} direction='left' role='button' />
      </div>
      <div className='pagination__details'>
        عرض {paginatedList.skip + 1} إلى {Math.min(paginatedList.skip + paginatedList.limit, paginatedList.total)} من{' '}
        {paginatedList.total}
      </div>
    </div>
  )
}

export default Pagination
