import React from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'
import { ListIcon } from '@qesheq/qesheq-ui'
import { useApolloClient } from '@apollo/client'

import { useAuth } from '../../hooks/auth.hook'
import './mini-header.styles.css'

type OutletContextInterface = [boolean, (isOpen: boolean) => void]

const MiniHeader = (): JSX.Element => {
  const { logout, user } = useAuth()
  const client = useApolloClient()
  const [isMenuOpen, setIsMenuOpen] = useOutletContext<OutletContextInterface>()

  const handleLogout = (): void => {
    logout(client)
  }

  return (
    <div className='mini-header-layout'>
      <aside className='mini-header'>
        <div className='mini-header__corner'>
          {user?.username && <div className='mini-header__logged-user'>{user.username}</div>}
          <div
            className='mini-header__logout'
            onClick={handleLogout}
            onKeyPress={handleLogout}
            role='button'
            tabIndex={0}>
            الخروج
          </div>
        </div>
        {setIsMenuOpen && (
          <div
            role='button'
            tabIndex={0}
            className={`mini-header__burger-menu-toggle ${isMenuOpen ? 'mini-header__burger-menu-toggle--open' : ''}`}
            onClick={() => setIsMenuOpen(true)}
            onKeyPress={() => setIsMenuOpen(true)}>
            <ListIcon />
          </div>
        )}
      </aside>
      <Outlet />
    </div>
  )
}

export default MiniHeader
