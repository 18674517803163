import React, { useEffect, useState } from 'react'
import { Spinner } from '@qesheq/qesheq-ui'

import TableCell from './table-cell.component'
import { PaginatedList } from '../../providers/graphql/types/paginaton.types'
import { RowCell, TableColumn, TableOptions } from './table.types'
import './table.styles.css'

interface TableProps {
  columns: TableColumn[]
  dataSource: PaginatedList<RowCell>
  options?: TableOptions
}

const Table = ({ columns, dataSource, options }: TableProps): JSX.Element => {
  const { isLoading, actions, onSelect } = options!
  const [extendedColumns, setExtendedColumns] = useState(columns)

  useEffect(() => {
    if (onSelect && extendedColumns.length === columns.length) {
      setExtendedColumns((previousValue) => [{ key: 'select', label: '', type: 'selection' }, ...previousValue])
    }
    if (actions?.length) {
      setExtendedColumns((previousValue) => [...previousValue, { key: 'actions', label: '', type: 'actions' }])
    }
    return () => {
      setExtendedColumns((previousValue) =>
        previousValue.filter((column) => !['actions', 'select'].includes(column.key))
      )
    }
  }, [])

  return (
    <table className='listing-table'>
      <thead>
        <tr className='listing-table__row listing-table__header-row'>
          {extendedColumns.map((header) => (
            <th key={header.key} className='listing-table__header-cell'>
              {header.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {dataSource.data.map((row) => (
          <tr key={`row-${row.id}`} className='listing-table__row listing-table__content-row'>
            {extendedColumns.map((column) => (
              <TableCell key={`${column.key}-${row.id}`} row={row} column={column} options={options} />
            ))}
          </tr>
        ))}
      </tbody>
      {isLoading && (
        <div className='listing-table__spinner-contianer'>
          <Spinner layout='qesheq-boxed' customClassName='listing-table__spinner' />
        </div>
      )}
    </table>
  )
}

Table.defaultProps = {
  options: {},
}

export default Table
