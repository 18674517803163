import React, { ChangeEvent, useState } from 'react'
import { v4 as uuid } from 'uuid'

import './checkbox.styles.css'

type LayoutType = 'regular' | 'compact'
interface CheckboxProps {
  label?: string
  isChecked: boolean
  isDisabled?: boolean
  customClassName?: string
  layout?: LayoutType
  fillWidth?: boolean
  reversed?: boolean
  onChange?: (isChecked: boolean) => void
}

const Checkbox = (props: CheckboxProps): JSX.Element => {
  const { label, isChecked, onChange, customClassName, isDisabled, layout, fillWidth, reversed } = props
  const [id] = useState(uuid())

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      onChange(e.target.checked)
    }
  }

  return (
    <div className={`checkbox checkbox--${layout} ${customClassName || ''}${isDisabled ? ' checkbox--disabled' : ''}`}>
      <input
        type='checkbox'
        className={`checkbox__input checkbox__input--${layout}`}
        checked={isChecked}
        onChange={handleOnChange}
        id={id}
        disabled={isDisabled}
      />
      <label
        className={`checkbox__label checkbox__label--${layout} ${fillWidth ? `checkbox__label--fill-width` : ''} ${
          reversed ? `checkbox__label--reversed` : ''
        }`}
        htmlFor={id}>
        {label}
      </label>
    </div>
  )
}

Checkbox.defaultProps = {
  layout: 'regular',
  fillWidth: false,
  reversed: false,
}

export default Checkbox
