import React, { useCallback, useMemo, useState } from 'react'
import { gql } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { Button, TrashIcon, PencilSimpleIcon } from '@qesheq/qesheq-ui'
import '@qesheq/qesheq-ui/dist/css/q-editor.styles.css'

import { ActionBar, Card, PageHeader, Pagination, Table } from '../../components'
import { Writer } from '../../types/writer.types'
import { RowCell, TableColumn } from '../../components/table/table.types'

import './writers.styles.css'
import { useListing } from '../../hooks'

const WritersQuery = gql`
  query Writers($sort: SortInput, $pagination: PaginationInput, $filters: BaseFiltersInput) {
    writers(sort: $sort, filters: $filters, pagination: $pagination) {
      total
      limit
      skip
      data {
        id
        fullname
        image
        coverImage
        isPublished
        articlesCount
      }
    }
  }
`
const ArchiveWritersQuery = gql`
  mutation archiveWriters($ids: [ObjectId!]!) {
    archiveWriters(ids: $ids)
  }
`

const columns: TableColumn[] = [
  {
    key: 'fullname',
    label: 'الأسم الكامل',
    type: 'text',
    isTitle: true,
  },
  {
    key: 'image',
    label: 'الصورة الرئيسية',
    type: 'image',
  },
  {
    key: 'coverImage',
    label: 'صورة الغلاف',
    type: 'image',
  },
  {
    key: 'articlesCount',
    label: 'عدد المقالات',
    type: 'text',
  },
  {
    key: 'isPublished',
    label: 'منشور',
    type: 'check',
  },
]

const WritersContainer = (): JSX.Element => {
  const navigate = useNavigate()

  const {
    data: writers,
    isFetching,
    error,
    page,
    deleteEntries: deleteWriters,
    changePage,
    changePageLimit,
  } = useListing<Writer>({
    listingQuery: WritersQuery,
    archiveDocumentMutation: ArchiveWritersQuery,
    gqlDocumentsKey: 'writers',
    gqlArchiveMutationKey: 'archiveWriters',
    filters: {},
  })

  const [selectedWriters, setSelectedWriters] = useState<Set<RowCell['id']>>(new Set())

  const handleSelectAll = (): void => {
    const allIds = writers.data.map((writer: Writer) => writer.id)
    setSelectedWriters(new Set(allIds))
  }

  const handleUnselectAll = (): void => {
    setSelectedWriters(new Set())
  }

  const handleOnSelect = useCallback((id: RowCell['id'], isSelected: boolean) => {
    setSelectedWriters((prevState) => {
      const newSet = new Set(prevState)
      if (isSelected) {
        newSet.add(id)
      } else {
        newSet.delete(id)
      }
      return newSet
    })
  }, [])

  const handleOnBulkDelete = async (): Promise<void> => {
    if (selectedWriters.size) {
      await deleteWriters(Array.from(selectedWriters))
      setSelectedWriters(new Set())
    }
  }

  const handleOnDelete = async (id: RowCell['id']): Promise<void> => {
    await deleteWriters([id])
  }

  const isActionBarOpen = useMemo(() => !!selectedWriters.size, [selectedWriters])

  if (error) return <Card>SOMETHING WENT WRONG!</Card>

  return (
    <>
      <PageHeader title='الكتّاب'>
        <Button
          type='link'
          label='أضف كاتب'
          layout='solid'
          href='/writer/new'
          customClassName='page-header__add-item'
        />
      </PageHeader>
      <Card>
        {writers?.data && (
          <Table
            columns={columns}
            dataSource={writers}
            options={{
              mediaBucket: 'writer',
              onSelect: handleOnSelect,
              selectedRows: selectedWriters,
              isLoading: isFetching,
              actions: [
                { title: 'Delete', onClick: handleOnDelete, icon: <TrashIcon size={16} />, colorStyle: 'secondary' },
                { title: 'Edit', onClick: (id) => navigate(`/writer/${id}`), icon: <PencilSimpleIcon size={16} /> },
              ],
            }}
          />
        )}
      </Card>
      <Card>
        <Pagination paginatedList={writers} page={page} onPageChange={changePage} onLimitChange={changePageLimit} />
      </Card>
      <ActionBar isOpen={isActionBarOpen}>
        <Button
          type='button'
          label='تحديد الكل'
          layout='outline'
          customClassName='listing-action-button'
          onClick={handleSelectAll}
        />
        <Button
          type='button'
          label='إلغاء تحديد الكل'
          layout='outline'
          colorStyle='secondary'
          customClassName='listing-action-button'
          onClick={handleUnselectAll}
        />
        <Button
          type='button'
          label='حذف'
          layout='solid'
          colorStyle='secondary'
          customClassName='listing-action-button'
          onClick={handleOnBulkDelete}
        />
      </ActionBar>
    </>
  )
}

export default WritersContainer
