import React, { ReactNode } from 'react'

import './action-bar.styles.css'

interface ActionBarProps {
  isOpen: boolean
  customClassName?: string
  children?: ReactNode
}

const ActionBar = ({ isOpen, customClassName, children }: ActionBarProps): JSX.Element => (
  <div className={`action-bar action-bar${isOpen ? '--open' : ''} ${customClassName}`}>{children}</div>
)

export default ActionBar
