import React, { useEffect, useState } from 'react'
import { Outlet, Link, useLocation } from 'react-router-dom'
import {
  GaugeIcon,
  ArticleIcon,
  BrowsersIcon,
  PuzzlePieceIcon,
  TagSimpleIcon,
  UserCircleIcon,
  ArrowLineLeftIcon,
  ArrowLineRightIcon,
  UsersIcon,
  ChatsIcon,
} from '@qesheq/qesheq-ui'
import './side-panel.styles.css'

const navActiveKey = 'side-panel__navigation-item--active'
interface CollapseToggleProps {
  isCollapsed: boolean
  setIsCollapsed: (isCollapsed: boolean) => void
}

const CollapseToggle = ({ isCollapsed, setIsCollapsed }: CollapseToggleProps): JSX.Element => {
  const toggleProps = {
    className: 'side-panel__toggle',
    onClick: () => setIsCollapsed(!isCollapsed),
  }
  return isCollapsed ? (
    <ArrowLineLeftIcon size={26} {...toggleProps} />
  ) : (
    <ArrowLineRightIcon size={26} {...toggleProps} />
  )
}

const SidePanel = (): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [lastScrollPosition, setLasScrollPosition] = useState(0)

  const { pathname } = useLocation()

  const pathChunks = pathname.split('/')
  const currentRoute = pathChunks.length > 1 ? pathChunks[1] : ''

  useEffect(() => {
    if (isMenuOpen) {
      setLasScrollPosition(window.pageYOffset)
    } else {
      const tempPosition = lastScrollPosition
      setLasScrollPosition(0)
      window.scrollTo({ top: tempPosition })
    }
  }, [isMenuOpen])

  return (
    <div className='private-container'>
      <aside
        className={`side-panel ${isCollapsed ? 'side-panel--collapsed' : ''} ${isMenuOpen ? 'side-panel--open' : ''}`}>
        <div className='side-panel__wrapper'>
          <Link to='/' className={`side-panel__logo ${isCollapsed ? 'side-panel__logo--collapsed' : ''}`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/logo-mini-white-40x38.svg`}
              alt='Basil'
              className='side-panel__logo-image-mini'
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/logo-white-160x69.svg`}
              alt='Basil'
              className='side-panel__logo-image-large'
            />
          </Link>
          <nav className='side-panel__navigation'>
            <Link
              to='/dashboard'
              className={`side-panel__navigation-item ${['', 'dashboard'].includes(currentRoute) ? navActiveKey : ''}`}>
              <GaugeIcon size={26} />
              <span className='side-panel__navigation-item-label'>الرئسية</span>
            </Link>
            <Link to='/pages' className={`side-panel__navigation-item ${currentRoute === 'pages' ? navActiveKey : ''}`}>
              <BrowsersIcon size={26} />
              <span className='side-panel__navigation-item-label'>الصفحات</span>
            </Link>
            <Link
              to='/articles'
              className={`side-panel__navigation-item ${currentRoute === 'articles' ? navActiveKey : ''}`}>
              <ArticleIcon size={26} />
              <span className='side-panel__navigation-item-label'>المقالات</span>
            </Link>
            <Link
              to='/categories'
              className={`side-panel__navigation-item ${currentRoute === 'categories' ? navActiveKey : ''}`}>
              <PuzzlePieceIcon size={26} />
              <span className='side-panel__navigation-item-label'>المواضيع</span>
            </Link>
            <Link
              to='/writers'
              className={`side-panel__navigation-item ${currentRoute === 'writers' ? navActiveKey : ''}`}>
              <UserCircleIcon size={26} />
              <span className='side-panel__navigation-item-label'>الكتّاب</span>
            </Link>
            <Link to='/tags' className={`side-panel__navigation-item ${currentRoute === 'tags' ? navActiveKey : ''}`}>
              <TagSimpleIcon size={26} />
              <span className='side-panel__navigation-item-label'>الكلمات الدليلية</span>
            </Link>
            <Link
              to='/inquiries'
              className={`side-panel__navigation-item ${currentRoute === 'inquiries' ? navActiveKey : ''}`}>
              <ChatsIcon size={26} />
              <span className='side-panel__navigation-item-label'>استفسارات</span>
            </Link>
            <Link to='/users' className={`side-panel__navigation-item ${currentRoute === 'users' ? navActiveKey : ''}`}>
              <UsersIcon size={26} />
              <span className='side-panel__navigation-item-label'>إدارةالمستخدم</span>
            </Link>
          </nav>

          <CollapseToggle isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
        </div>
      </aside>
      <section
        className={`main-section ${isCollapsed ? 'main-section--expanded' : ''} ${
          isMenuOpen && lastScrollPosition ? 'main-section--fixed' : ''
        }`}
        style={{ top: -lastScrollPosition }}>
        <Outlet context={[isMenuOpen, setIsMenuOpen]} />
      </section>
      <div
        role='button'
        tabIndex={-1}
        className={`private-container__overlay ${isMenuOpen ? 'private-container__overlay--visible' : ''}`}
        onClick={() => setIsMenuOpen(false)}
        onKeyPress={() => setIsMenuOpen(false)}
      />
    </div>
  )
}

export default SidePanel
