import React, { ReactNode } from 'react'

import './page-header.styles.css'

interface PageHeaderProps {
  title: string
  children?: ReactNode
}

const PageHeader = ({ title, children }: PageHeaderProps): JSX.Element => (
  <div className='page-header'>
    <h1 className='page-header__title'>{title}</h1>
    {children && <div className='page-header__content'>{children}</div>}
  </div>
)

export default PageHeader
