import { Button, CloseIcon } from '@qesheq/qesheq-ui'
import React, { useEffect, useMemo, useState } from 'react'

import './alert.styles.css'

const DEFAULT_ALERT_TIME = 3000

export interface AlertInterface {
  title?: string
  description?: string
  type: 'info' | 'warning' | 'error' | 'success'
  behavior: 'timed' | 'controlled' | 'timed-and-controlled'
  time?: number
}

interface AlertProps {
  customClassName?: string
  alerts: Array<AlertInterface>
  updateAlerts: (alerts: AlertInterface[]) => void
}

/**
 * @description Component by queue array. Push new alerts to the controlling array to display them.
 *
 * @param alerts array of alerts to be processed as a queue
 * @param updateAlerts alerts array setter to give control to the compoonent
 */
const Alert = ({ customClassName, alerts, updateAlerts }: AlertProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const [activeAlert, setActiveAlert] = useState<AlertInterface | null>(null)
  const [lastActiveAlert, setLastActiveAlert] = useState<AlertInterface | null>(null)
  const [activeTimeout, setActiveTimeout] = useState<NodeJS.Timeout | null>(null)

  const removeActiveAlert = (): void => {
    setIsOpen(false)
    if (activeTimeout) {
      clearInterval(activeTimeout)
      setActiveTimeout(null)
    }
    setTimeout(() => {
      setLastActiveAlert(activeAlert)
      setActiveAlert(null)
    }, 500)
  }

  const processNextAlert = (): void => {
    if (!alerts.length) {
      return
    }
    if (!activeAlert) {
      const currentAlert = alerts.shift() || null
      setActiveAlert(currentAlert)
      updateAlerts(alerts)
      setIsOpen(true)
      if (currentAlert?.behavior?.includes('timed')) {
        setActiveTimeout(
          setTimeout(() => {
            removeActiveAlert()
          }, currentAlert.time || DEFAULT_ALERT_TIME)
        )
      }
    }
  }

  useEffect(() => {
    processNextAlert()
  }, [alerts, activeAlert])

  const title = useMemo(() => activeAlert?.title || lastActiveAlert?.title, [activeAlert, lastActiveAlert])
  const description = useMemo(
    () => activeAlert?.description || lastActiveAlert?.description,
    [activeAlert, lastActiveAlert]
  )
  const behavior = useMemo(() => activeAlert?.behavior || lastActiveAlert?.behavior, [activeAlert, lastActiveAlert])
  const type = useMemo(() => activeAlert?.type || lastActiveAlert?.type, [activeAlert, lastActiveAlert])

  return (
    <div
      className={`alert-container alert-container${
        isOpen ? '--open' : ''
      } alert-container--${type} ${customClassName}`}>
      {title && <span className='alert-container__title'>{title}</span>}
      {description && <span className='alert-container__description'>{description}</span>}
      {behavior?.includes('controlled') && (
        <Button
          label=''
          onClick={removeActiveAlert}
          customClassName='alert-container__close'
          icon={<CloseIcon />}
          layout='icon'
          type='button'
        />
      )}
    </div>
  )
}

export default Alert
