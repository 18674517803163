import React, { useCallback, useMemo, useState } from 'react'
import { gql } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { TrashIcon, PencilSimpleIcon, Button } from '@qesheq/qesheq-ui'
import '@qesheq/qesheq-ui/dist/css/q-editor.styles.css'

import { ActionBar, Card, PageHeader, Pagination, Table } from '../../components'
import { User } from '../../types/user.types'
import { RowCell, TableColumn } from '../../components/table/table.types'

import './users.styles.css'
import { useListing } from '../../hooks'

const UsersQuery = gql`
  query Users($sort: SortInput, $pagination: PaginationInput, $filters: BaseFiltersInput) {
    users(sort: $sort, filters: $filters, pagination: $pagination) {
      total
      limit
      skip
      data {
        id
        email
        permissions
      }
    }
  }
`
const ArchiveUsersQuery = gql`
  mutation archiveUsers($ids: [ObjectId!]!) {
    archiveUsers(ids: $ids)
  }
`

const columns: TableColumn[] = [
  {
    key: 'email',
    label: 'البريد الإلكتروني',
    type: 'text',
    isTitle: true,
  },
]

const UsersContainer = (): JSX.Element => {
  const navigate = useNavigate()

  const {
    data: users,
    isFetching,
    error,
    page,
    deleteEntries: deleteUsers,
    changePage,
    changePageLimit,
  } = useListing<User>({
    listingQuery: UsersQuery,
    archiveDocumentMutation: ArchiveUsersQuery,
    gqlDocumentsKey: 'users',
    gqlArchiveMutationKey: 'archiveUsers',
    filters: {},
  })

  const [selectedUsers, setSelectedUsers] = useState<Set<RowCell['id']>>(new Set())

  const handleSelectAll = (): void => {
    const allIds = users.data.map((user: User) => user.id)
    setSelectedUsers(new Set(allIds))
  }

  const handleUnselectAll = (): void => {
    setSelectedUsers(new Set())
  }

  const handleOnSelect = useCallback((id: RowCell['id'], isSelected: boolean) => {
    setSelectedUsers((prevState) => {
      const newSet = new Set(prevState)
      if (isSelected) {
        newSet.add(id)
      } else {
        newSet.delete(id)
      }
      return newSet
    })
  }, [])

  const handleOnBulkDelete = async (): Promise<void> => {
    if (selectedUsers.size) {
      await deleteUsers(Array.from(selectedUsers))
      setSelectedUsers(new Set())
    }
  }

  const handleOnDelete = async (id: RowCell['id']): Promise<void> => {
    await deleteUsers([id])
  }

  const isActionBarOpen = useMemo(() => !!selectedUsers.size, [selectedUsers])

  if (error) return <Card>SOMETHING WENT WRONG!</Card>

  return (
    <>
      <PageHeader title='إدارةالمستخدم'>
        <Button
          type='link'
          label='أضف مستخدم'
          layout='solid'
          href='/user/new'
          customClassName='page-header__add-item'
        />
      </PageHeader>
      <Card>
        {users?.data && (
          <Table
            columns={columns}
            dataSource={users}
            options={{
              mediaBucket: 'user',
              onSelect: handleOnSelect,
              selectedRows: selectedUsers,
              isLoading: isFetching,
              actions: [
                { title: 'Delete', onClick: handleOnDelete, icon: <TrashIcon size={16} />, colorStyle: 'secondary' },
                { title: 'Edit', onClick: (id) => navigate(`/user/${id}`), icon: <PencilSimpleIcon size={16} /> },
              ],
            }}
          />
        )}
      </Card>
      <Card>
        <Pagination paginatedList={users} page={page} onPageChange={changePage} onLimitChange={changePageLimit} />
      </Card>
      <ActionBar isOpen={isActionBarOpen}>
        <Button
          type='button'
          label='تحديد الكل'
          layout='outline'
          customClassName='listing-action-button'
          onClick={handleSelectAll}
        />
        <Button
          type='button'
          label='إلغاء تحديد الكل'
          layout='outline'
          colorStyle='secondary'
          customClassName='listing-action-button'
          onClick={handleUnselectAll}
        />
        <Button
          type='button'
          label='حذف'
          layout='solid'
          colorStyle='secondary'
          customClassName='listing-action-button'
          onClick={handleOnBulkDelete}
        />
      </ActionBar>
    </>
  )
}

export default UsersContainer
