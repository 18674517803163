import React from 'react'
import { createRoot } from 'react-dom/client'

import { BrowserRouter } from 'react-router-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { GraphQLProvider } from './providers/graphql'
import { AuthProvider } from './hooks/auth.hook'

const root = createRoot(document.getElementById('root')!)

root.render(
  <AuthProvider>
    <GraphQLProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </GraphQLProvider>
  </AuthProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
