import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import { Button } from '@qesheq/qesheq-ui'

import { RowCell, TableColumn, TableOptions } from './table.types'
import { Checkbox } from '..'

interface TableCellProps {
  row: RowCell
  column: TableColumn
  options?: TableOptions
}

const TableCell = ({ row, column, options }: TableCellProps): JSX.Element => {
  const handleRowSelection = (isChecked: boolean): void => {
    if (options?.onSelect) {
      options.onSelect(row.id, isChecked)
    }
  }

  const { type, key, isNested, label, isTitle } = column
  const isSelected = !!options?.selectedRows?.has(row.id)
  const content = useMemo(() => {
    const value = isNested ? row[key].value : row[key]
    switch (type) {
      case 'image':
        if (!value) {
          return 'صورة غير متاحة'
        }
        return (
          <object data={`${process.env.REACT_APP_MEDIA_URL}raw/${options?.mediaBucket}/${value}`} type='image/jpeg'>
            <img
              src={`${process.env.REACT_APP_MEDIA_URL}resized/110x77/${value}`}
              srcSet={`${process.env.REACT_APP_MEDIA_URL}resized/300x197/${value} 300w, https://assets.qesheq.com/resized/110x77/${value} 110w`}
              sizes='(max-width: 768px) 300px, 110px'
              alt={value}
            />{' '}
          </object>
        )
      case 'check':
        return value ? 'نعم' : 'كلا'
      case 'date':
        return dayjs(value).format('YYYY MMMM DD')
      case 'actions':
        return options?.actions?.map((action) => (
          <Button
            customClassName='listing-table__action-button'
            label={action.title}
            onClick={() => action.onClick(row.id)}
            layout='icon-solid'
            colorStyle={action.colorStyle}
            type='button'
            icon={action.icon}
          />
        ))
      case 'selection':
        return (
          <Checkbox
            isChecked={isSelected}
            onChange={handleRowSelection}
            customClassName='listing-table__checkbox'
            layout='compact'
          />
        )
      default:
        return value
    }
  }, [row, key, isSelected])

  const mobileLabel = useMemo(() => {
    if (['title'].includes(key) || ['image', 'action'].includes(type)) {
      return undefined
    }
    return label
  }, [type, label, key])

  return (
    <td
      className={`listing-table__content-cell ${mobileLabel ? 'listing-table__content-cell--labeled' : ''} ${
        type === 'selection' || type === 'actions' ? 'listing-table__content-cell--aside' : ''
      }`}
      data-label={mobileLabel}>
      <div
        className={`listing-table__content-wrapper listing-table__content-wrapper-${type} ${
          isTitle ? 'listing-table__content-wrapper--title' : ''
        }`}>
        {content}
      </div>
    </td>
  )
}

export default TableCell
