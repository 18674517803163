import '@qesheq/qesheq-ui/dist/css/utils.css'
import '@qesheq/qesheq-ui/dist/css/base.css'
import { Spinner } from '@qesheq/qesheq-ui'
import './App.css'

import React, { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'

import { MainRouter } from './routers'
import { useAuth } from './hooks/auth.hook'
import { UserByTokenQuery } from './providers/graphql/queries/user-by-token.query'

function App(): JSX.Element {
  const { token, setUser, logout, user, setCheckingSession, checkingSession } = useAuth()
  const [getUserByToken, { loading, called }] = useLazyQuery(UserByTokenQuery)

  useEffect(() => {
    const validateToken = async (): Promise<void> => {
      const { client, error, data } = await getUserByToken({ variables: { token } })
      if (error || !data?.userByToken?.email) {
        logout(client)
      } else {
        setUser(data.userByToken)
      }
      setCheckingSession(false)
    }
    if (token && !user && !loading && !called) {
      validateToken()
    }
  }, [token, user, loading, called])

  if (checkingSession || loading) {
    return (
      <div className='main-container__session-loader-container'>
        <Spinner layout='qesheq-default' customClassName='main-container__session-loader' />
      </div>
    )
  }

  return (
    <div className='main-container'>
      <MainRouter />
    </div>
  )
}

export default App
