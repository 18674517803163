import React, { useCallback, useMemo, useState } from 'react'
import { gql } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { TrashIcon, PencilSimpleIcon, Button } from '@qesheq/qesheq-ui'
import '@qesheq/qesheq-ui/dist/css/q-editor.styles.css'

import { ActionBar, Card, PageHeader, Pagination, Table } from '../../components'
import { Article } from '../../types/article.types'
import { RowCell, TableColumn } from '../../components/table/table.types'
import { useListing } from '../../hooks'

import './articles.styles.css'

const ArticlesQuery = gql`
  query Articles($sort: SortInput, $pagination: PaginationInput, $filters: ArticleFiltersInput) {
    articles(sort: $sort, filters: $filters, pagination: $pagination) {
      total
      limit
      skip
      data {
        id
        title
        image
        date
        isPublished
        isFeatured
        isDeleted
        category {
          value: title
        }
        writer {
          value: fullname
        }
      }
    }
  }
`
const ArchiveArticlesQuery = gql`
  mutation archiveArticles($ids: [ObjectId!]!) {
    archiveArticles(ids: $ids)
  }
`

const columns: TableColumn[] = [
  {
    key: 'title',
    label: 'العنوان',
    type: 'text',
    isTitle: true,
  },
  {
    key: 'image',
    label: 'الصورة الرئيسية',
    type: 'image',
  },
  {
    key: 'writer',
    label: 'الكاتب',
    type: 'text',
    isNested: true,
  },
  {
    key: 'category',
    label: 'الموضوع',
    type: 'text',
    isNested: true,
  },
  {
    key: 'date',
    label: 'التاريخ',
    type: 'date',
  },
  {
    key: 'isPublished',
    label: 'منشور',
    type: 'check',
  },
  {
    key: 'isFeatured',
    label: 'بارز',
    type: 'check',
  },
]

const ArticlesContainer = (): JSX.Element => {
  const navigate = useNavigate()

  const {
    data: articles,
    isFetching,
    error,
    page,
    deleteEntries: deleteArticles,
    changePage,
    changePageLimit,
  } = useListing<Article>({
    listingQuery: ArticlesQuery,
    archiveDocumentMutation: ArchiveArticlesQuery,
    gqlDocumentsKey: 'articles',
    gqlArchiveMutationKey: 'archiveArticles',
    filters: {},
  })

  const [selectedArticles, setSelectedArticles] = useState<Set<RowCell['id']>>(new Set())

  const handleSelectAll = (): void => {
    const allIds = articles.data.map((article: Article) => article.id)
    setSelectedArticles(new Set(allIds))
  }

  const handleUnselectAll = (): void => {
    setSelectedArticles(new Set())
  }

  const handleOnSelect = useCallback((id: RowCell['id'], isSelected: boolean) => {
    setSelectedArticles((prevState) => {
      const newSet = new Set(prevState)
      if (isSelected) {
        newSet.add(id)
      } else {
        newSet.delete(id)
      }
      return newSet
    })
  }, [])

  const handleOnBulkDelete = async (): Promise<void> => {
    if (selectedArticles.size) {
      await deleteArticles(Array.from(selectedArticles))
      setSelectedArticles(new Set())
    }
  }

  const handleOnDelete = async (id: RowCell['id']): Promise<void> => {
    await deleteArticles([id])
  }

  const isActionBarOpen = useMemo(() => !!selectedArticles.size, [selectedArticles])

  if (error) return <Card>SOMETHING WENT WRONG!</Card>

  return (
    <>
      <PageHeader title='المقالات'>
        <Button
          type='link'
          label='اضف مقال'
          layout='solid'
          href='/article/new'
          customClassName='page-header__add-item'
        />
      </PageHeader>
      <Card>
        {articles?.data && (
          <Table
            columns={columns}
            dataSource={articles}
            options={{
              mediaBucket: 'article',
              onSelect: handleOnSelect,
              selectedRows: selectedArticles,
              isLoading: isFetching,
              actions: [
                {
                  title: 'Delete',
                  onClick: handleOnDelete,
                  icon: <TrashIcon size={16} />,
                  colorStyle: 'secondary',
                },
                { title: 'Edit', onClick: (id) => navigate(`/article/${id}`), icon: <PencilSimpleIcon size={16} /> },
              ],
            }}
          />
        )}
      </Card>
      <Card>
        <Pagination paginatedList={articles} page={page} onPageChange={changePage} onLimitChange={changePageLimit} />
      </Card>
      <ActionBar isOpen={isActionBarOpen}>
        <Button
          type='button'
          label='تحديد الكل'
          layout='outline'
          customClassName='listing-action-button'
          onClick={handleSelectAll}
        />
        <Button
          type='button'
          label='إلغاء تحديد الكل'
          layout='outline'
          colorStyle='secondary'
          customClassName='listing-action-button'
          onClick={handleUnselectAll}
        />
        <Button
          type='button'
          label='حذف'
          layout='solid'
          colorStyle='secondary'
          customClassName='listing-action-button'
          onClick={handleOnBulkDelete}
        />
      </ActionBar>
    </>
  )
}

export default ArticlesContainer
