import React, { ReactNode } from 'react'
import Card from '../card/card.component'

import './form-card.styles.css'

const minmax = (value: number, min: number, max: number): number => Math.max(Math.min(value, max), min)

interface FormCardProps {
  title?: string
  customClassName?: string
  columns?: number
  columnsGap?: number // 8pt grid eg: gap x 8px
  rowsGap?: number // 8pt grid eg: gap x 8px
  children: ReactNode
}

/**
 *
 * @param {string | undefined} props.title  title passed to the card component
 * @param {string | undefined} props.customClassName custom className passed to the card component
 * @param {string | undefined} props.columns number of columns (min: 1, max: 4) | Default : 1
 * @param {string | undefined} props.columnsGap units of gap between columns under the 8pt system (min: 1, max: 4) | Default : 1
 * @param {string | undefined} props.rowsGap units of gap between rows under the 8pt system (min: 1, max: 4) | Default : 1
 * @returns
 */
const FormCard = (props: FormCardProps): JSX.Element => {
  const { columns = 1, columnsGap = 0, rowsGap = 0, customClassName, children, ...drillProps } = props
  const className = `form-card ${customClassName}`
  const formClassName = `form-card__form form-card__form--col-${minmax(
    columns,
    1,
    4
  )} form-card__form--col-gap-${minmax(columnsGap, 0, 4)} form-card__form--row-gap-${minmax(rowsGap, 0, 4)}`
  return (
    <Card noGap customClassName={className} {...drillProps}>
      <div className={formClassName}>{children}</div>
    </Card>
  )
}

export default FormCard
