import React, { useMemo } from 'react'
import Select, { OnChangeValue, PropsValue } from 'react-select'
import AsyncSelect from 'react-select/async'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { v4 as uuid } from 'uuid'

import './dropdown-field.styles.css'

export interface DropdownOptionType<Option> {
  label: Option
  value: Option
}

interface DropdownFieldProps<Option, isMulti> {
  customClassName?: string
  options: any // OptionsOrGroups<Option, GroupBase<Option>> | undefined
  label?: string
  isDisabled?: boolean
  isClearable?: boolean
  errorMessage?: string | null
  placeholder?: string
  value: PropsValue<Option> | undefined
  setValue?: (value: Option | null) => void
  isMulti?: isMulti
  loadOptions?: (inputValue: string) => any
  onCreateOption?: (value: string) => void
}

const DropdownField = <Option, isMulti extends boolean = false>(
  props: DropdownFieldProps<Option, isMulti>
): JSX.Element => {
  const { label, errorMessage, customClassName, setValue, isMulti, loadOptions, onCreateOption, ...selectProps } = props

  const INPUT_ID = uuid()

  const handleOnChange = (newValue: OnChangeValue<Option, isMulti>): void => {
    if (setValue) {
      if (isMulti) {
        setValue(newValue as any)
      } else {
        setValue(newValue as Option)
      }
    }
  }

  const className = useMemo(
    () => `dropdown-field__select${errorMessage ? ' dropdown-field__select--error' : ''}`,
    [errorMessage]
  )

  return (
    <div
      className={`dropdown-field ${customClassName || ''}${selectProps.isDisabled ? ' dropdown-field--disabled' : ''}`}>
      {label ? (
        <label htmlFor={INPUT_ID} className='dropdown-field__label'>
          {label}
        </label>
      ) : null}
      <div className='dropdown-field__select-container'>
        {loadOptions && onCreateOption && (
          <AsyncCreatableSelect
            id={INPUT_ID}
            className={className}
            classNamePrefix='dropdown-field'
            onChange={handleOnChange}
            defaultValue={null}
            isMulti={isMulti}
            loadOptions={loadOptions}
            cacheOptions
            defaultOptions
            onCreateOption={onCreateOption}
            {...selectProps}
          />
        )}
        {loadOptions && !onCreateOption && (
          <AsyncSelect
            id={INPUT_ID}
            className={className}
            classNamePrefix='dropdown-field'
            onChange={handleOnChange}
            defaultValue={null}
            isMulti={isMulti}
            loadOptions={loadOptions}
            cacheOptions
            defaultOptions
            {...selectProps}
          />
        )}
        {!loadOptions && !onCreateOption && (
          <Select
            id={INPUT_ID}
            className={className}
            classNamePrefix='dropdown-field'
            onChange={handleOnChange}
            defaultValue={null}
            isMulti={isMulti}
            {...selectProps}
          />
        )}
        <span
          className={`dropdown-field__error-message${errorMessage ? ' dropdown-field__error-message--visible' : ''}`}>
          {errorMessage}
        </span>
      </div>
    </div>
  )
}

DropdownField.defaultProps = {
  placeholder: 'اختار',
}

export default DropdownField
