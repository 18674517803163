import { ApolloError, gql, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { RepeatIcon, Descendant, QEditor, serializeToText, Spinner, Button, InputField } from '@qesheq/qesheq-ui'
import React, { useEffect, useState, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { Alert, Checkbox, DropdownField, FormCard, ImageUploader, PageHeader } from '../../components'
import { AlertInterface } from '../../components/alert/alert.component'
import { DropdownOptionType } from '../../components/dropdown-field/dropdown-field.component'
import { useInputState } from '../../hooks'
import { ArticleDTO, TagDTO } from '../../providers/graphql/dto'
import { generateUrlKey } from '../../utils'
import { useAuth } from '../../hooks/auth.hook'
import './article-details.styles.css'

const articleFields = `
    id
    title
    urlKey
    image
    smallDescription
    description
    date
    dateCreated
    dateModified
    isDeleted
    isPublished
    isFeatured
    views
    category {
      value: id
      label: title
    }
    writer {
      value: id
      label: fullname
    }
    tags {
      value: id
      label: title
    }
`
const ArticleQuery = gql`
  query Article($id: String!) {
    article(id: $id) {
     ${articleFields}
    }
  }
`

const OptionsQueries = gql`
  query OptionsQueries(
    $categoriesSort: SortInput
    $categoriesPagination: PaginationInput
    $categoriesFilters: CategoryFiltersInput
    $writersSort: SortInput
    $writersPagination: PaginationInput
    $writersFilters: BaseFiltersInput
  ) {
    categories(sort: $categoriesSort, filters: $categoriesFilters, pagination: $categoriesPagination) {
      total
      limit
      skip
      data {
        value: id
        label: title
        isPublished
      }
    }
    writers(sort: $writersSort, filters: $writersFilters, pagination: $writersPagination) {
      total
      limit
      skip
      data {
        value: id
        label: fullname
        isPublished
      }
    }
  }
`

const TagsQuery = gql`
  query Tags($sort: SortInput, $pagination: PaginationInput, $filters: TagFiltersInput) {
    tags(sort: $sort, filters: $filters, pagination: $pagination) {
      total
      limit
      skip
      data {
        value: id
        label: title
        isPublished
      }
    }
  }
`
const CreateTagMutation = gql`
  mutation createTag($data: CreateTagInput!) {
    createTag(data: $data) {
      value: id
      label: title
    }
  }
`
const CreateArticleMutation = gql`
  mutation createArticle($data: CreateArticleInput!) {
    createArticle(data: $data) {
      ${articleFields}
    }
  }
`
const UpdateArticleMutation = gql`
  mutation updateArticle($id: String!, $data: UpdateArticleInput!) {
    updateArticle(id: $id, data: $data) {
      ${articleFields}
    }
  }
`
const ArchiveArticleMutation = gql`
  mutation archiveArticles($ids: [ObjectId!]!) {
    archiveArticles(ids: $ids)
  }
`

const serializeTags = (
  tags: Array<DropdownOptionType<string>> | undefined | null
): Array<Partial<TagDTO>> | undefined => {
  if (!tags) {
    return undefined
  }
  return tags.map((tag) => ({ id: tag.value, title: tag.label }))
}

const ArticleDetailsContainer = (): JSX.Element => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { token } = useAuth()

  const [isLoading, setIsLoading] = useState(false)
  const [alerts, setAlerts] = useState<Array<AlertInterface>>([])

  const [categories, setCategories] = useState<Array<DropdownOptionType<string>>>([])
  const [writers, setWriters] = useState<Array<DropdownOptionType<string>>>([])

  const [isPublished, setIsPublished] = useState(false)
  const [isFeatured, setIsFeatured] = useState<boolean>(false)

  const {
    value: title,
    setValue: setTitle,
    error: titleError,
    validate: validateTitle,
    onBlur: titleOnBlur,
  } = useInputState({ initialValue: '', validation: { required: true } })

  const {
    value: date,
    setValue: setDate,
    error: dateError,
    validate: validateDate,
    onBlur: dateOnBlur,
  } = useInputState<Date | undefined>({ initialValue: undefined, validation: { required: true } })

  const {
    value: urlKey,
    setValue: setUrlKey,
    error: urlKeyError,
    validate: validateUrlKey,
    onBlur: urlKeyOnBlur,
  } = useInputState({ initialValue: '', validation: { required: true } })

  const {
    value: smallDescription,
    setValue: setSmallDescription,
    error: smallDescriptionError,
    validate: validateSmallDescription,
    onBlur: smallDescriptionOnBlur,
  } = useInputState<string | undefined>({ initialValue: '', validation: { required: true } })

  const {
    value: category,
    setValue: setCategory,
    error: categoryError,
    validate: validateCategory,
  } = useInputState<DropdownOptionType<string> | null>({ initialValue: null, validation: { required: true } })

  const {
    value: writer,
    setValue: setWriter,
    error: writerError,
    validate: validateWriter,
  } = useInputState<DropdownOptionType<string> | null>({ initialValue: null, validation: { required: true } })

  const {
    value: selectedTags,
    setValue: setSelectedTags,
    error: tagsError,
    validate: validateTags,
  } = useInputState<Array<DropdownOptionType<string>> | null | undefined>({ initialValue: undefined, validation: {} })

  const {
    value: description,
    setValue: setDescription,
    validate: validateDescription,
  } = useInputState<Descendant[] | undefined>({
    initialValue: undefined,
    validation: { required: true },
  })

  const {
    value: image,
    setValue: setImage,
    validate: validateImage,
  } = useInputState<string | null>({ initialValue: null, validation: { required: true } })

  const [fetchArticle, { loading: articleLoading, data: articleData }] = useLazyQuery(ArticleQuery, {
    variables: {
      id,
    },
    notifyOnNetworkStatusChange: true,
  })

  const { data: optionsData } = useQuery(OptionsQueries, {
    variables: {
      categoriesFilters: { isPublished: true },
      writersFilters: { isPublished: true },
      categoriesPagination: {
        limit: 50,
      },
      writersPagination: {
        limit: 50,
      },
    },
    notifyOnNetworkStatusChange: true,
  })

  const { data: tagsData, refetch: TagsRefetch } = useQuery(TagsQuery, {
    variables: {
      filters: { isPublished: true, title: '' },
      pagination: {
        limit: 50,
      },
    },
    notifyOnNetworkStatusChange: true,
  })

  const [createTag] = useMutation(CreateTagMutation)
  const [createArticle] = useMutation(CreateArticleMutation)
  const [updateArticle] = useMutation(UpdateArticleMutation)
  const [archiveArticle] = useMutation(ArchiveArticleMutation)

  const setArticleValues = (article: ArticleDTO): void => {
    setIsFeatured(article.isFeatured || false)
    setIsPublished(article.isPublished)
    setTitle(article.title)
    setUrlKey(article.urlKey)
    setCategory(article.category as unknown as DropdownOptionType<string>)
    setWriter(article.writer as unknown as DropdownOptionType<string>)
    setSelectedTags((article.tags || undefined) as DropdownOptionType<string>[] | undefined)
    setSmallDescription(article.smallDescription)
    setDescription(article.description)
    setDate(new Date(article.date))
    setImage(article.image || null)
  }

  useEffect(() => {
    if (id && id !== 'new') {
      fetchArticle()
    }
  }, [id])

  useEffect(() => {
    setCategories(optionsData?.categories?.data || [])
    setWriters(optionsData?.writers?.data || [])
  }, [optionsData])

  useEffect(() => {
    if (articleData?.article) {
      setArticleValues(articleData?.article)
    }
  }, [articleData])

  useEffect(() => {
    setIsLoading(articleLoading)
  }, [articleLoading])

  const fetchTagsOptions = useCallback(
    async (inputValue: string) => {
      const tagsResult = await TagsRefetch({
        filters: {
          isPublished: true,
          title: inputValue,
        },
      })
      return tagsResult?.data?.tags?.data || []
    },
    [TagsRefetch]
  )

  const handleUrlKeyClick = (): void => {
    setUrlKey(generateUrlKey(title))
  }

  const handleCreateTag = async (value: string): Promise<void> => {
    const { data: createTagData, errors } = await createTag({
      variables: { data: { title: value, isPublished: true } },
    })
    if (!errors && createTagData?.createTag) {
      setSelectedTags([...(selectedTags || []), createTagData?.createTag])
    }
  }

  const handleArchiveArticle = async (): Promise<void> => {
    if (id) {
      await archiveArticle({
        variables: {
          ids: [id],
        },
        onCompleted: () => {
          navigate('/articles')
        },
        onError: (err) => {
          setAlerts((prevAlerts) => [
            ...prevAlerts,
            { behavior: 'timed-and-controlled', type: 'error', description: err?.message, time: 5000 },
          ])
        },
      })
    }
  }

  const handleSaveForm = async (): Promise<void> => {
    let isFormValid = true
    isFormValid = validateTitle() && isFormValid
    isFormValid = validateSmallDescription() && isFormValid
    isFormValid = validateCategory() && isFormValid
    isFormValid = validateWriter() && isFormValid
    isFormValid = validateTags() && isFormValid
    isFormValid = validateDescription() && isFormValid
    isFormValid = validateDate() && isFormValid
    isFormValid = validateUrlKey() && isFormValid
    isFormValid = validateImage() && isFormValid

    if (!isLoading && isFormValid) {
      setIsLoading(true)
      const payload = {
        title,
        urlKey,
        description,
        rawDescription: serializeToText(description || null),
        smallDescription,
        date,
        isPublished,
        isFeatured,
        image,
        writerId: writer?.value,
        categoryId: category?.value,
        tags: serializeTags(selectedTags),
      }

      let updatedArticle: ArticleDTO
      let submissionError: ApolloError | undefined

      if (id === 'new') {
        const { data } = await createArticle({
          variables: {
            data: payload,
          },
          onError: (err) => {
            submissionError = err
          },
        })
        updatedArticle = data?.createArticle
      } else {
        const { data } = await updateArticle({
          variables: {
            id,
            data: payload,
          },
          onError: (err) => {
            submissionError = err
          },
        })
        updatedArticle = data?.updateArticle
      }

      if (submissionError) {
        setAlerts((prevAlerts) => [
          ...prevAlerts,
          { behavior: 'timed-and-controlled', type: 'error', description: submissionError?.message, time: 5000 },
        ])
      } else if (updatedArticle) {
        setAlerts((prevAlerts) => [...prevAlerts, { behavior: 'timed', type: 'success', description: 'تم حفظ المقال' }])
        if (id === 'new') {
          navigate(`/article/${updatedArticle.id}`, { replace: true })
        } else {
          setArticleValues(updatedArticle)
        }
      }

      setIsLoading(false)

      // if (!errors && newArticle?.createArticle || ) {
      //   setSelectedTags([...(selectedTags || []), createTagData?.createTag])
      // }
    } else {
      setAlerts((prevAlerts) => [
        ...prevAlerts,
        {
          behavior: 'timed-and-controlled',
          type: 'error',
          title: 'راجع الحقول',
          description: `بعض الحقول غير مكتملة أو غير صالحة
        `,
        },
      ])
    }
  }

  return (
    <div className='article-details'>
      <Alert alerts={alerts} updateAlerts={setAlerts} />
      <PageHeader title='مقال'>
        {id !== 'new' && (
          <Button
            type='button'
            label='حذف'
            layout='solid'
            colorStyle='secondary'
            customClassName='page-header__button'
            isDisabled={isLoading}
            onClick={handleArchiveArticle}
          />
        )}
        <Button
          type='link'
          label='خروج'
          layout='outline'
          href='/articles'
          colorStyle='secondary'
          customClassName='page-header__button'
          isDisabled={isLoading}
        />
        <Button
          type='button'
          label='حفط'
          layout='solid'
          customClassName='page-header__button'
          isDisabled={isLoading}
          onClick={handleSaveForm}
        />
      </PageHeader>
      <div className='article-details__container'>
        {isLoading && (
          <div className='article-details__spinner-overlay'>
            <Spinner layout='qesheq-boxed' />
          </div>
        )}
        <FormCard
          customClassName='article-details__card article-details__visibility-card'
          title='الرؤية'
          columns={2}
          columnsGap={2}>
          <Checkbox label='منشور' isChecked={isPublished} onChange={setIsPublished} reversed />
          <Checkbox label='متميز' isChecked={isFeatured} onChange={setIsFeatured} reversed />
        </FormCard>
        <FormCard customClassName='article-details__card article-details__media-card' title='الصور'>
          <ImageUploader label='الصورة الرئيسة' setImage={setImage} image={image} section='article' location='main' />
        </FormCard>
        <FormCard
          customClassName='article-details__card article-details__info-card'
          title='معلومات'
          columns={2}
          columnsGap={4}
          rowsGap={4}>
          <InputField
            value={title}
            onChange={setTitle}
            errorMessage={titleError}
            label='العنوان'
            onBlur={titleOnBlur}
          />
          <InputField
            value={urlKey}
            onChange={setUrlKey}
            errorMessage={urlKeyError}
            label='مفتاح العنوان'
            onBlur={urlKeyOnBlur}
            icon={<RepeatIcon />}
            iconOnClick={handleUrlKeyClick}
          />
          <InputField
            type='date'
            value={date}
            onChange={setDate}
            errorMessage={dateError}
            label='التاريخ'
            onBlur={dateOnBlur}
          />
          <DropdownField
            value={category}
            setValue={setCategory}
            options={categories}
            label='الموضوع'
            errorMessage={categoryError}
            isClearable
          />
          <DropdownField
            value={writer}
            setValue={setWriter}
            options={writers}
            label='الكاتب'
            errorMessage={writerError}
            isClearable
          />
          <InputField
            type='text'
            value={smallDescription}
            onChange={setSmallDescription}
            errorMessage={smallDescriptionError}
            label='ملخص'
            onBlur={smallDescriptionOnBlur}
          />
          <DropdownField
            value={selectedTags}
            setValue={setSelectedTags}
            options={tagsData?.tags?.data || []}
            label='الكلمات الدليلية'
            errorMessage={tagsError}
            isClearable
            isMulti
            loadOptions={fetchTagsOptions}
            onCreateOption={handleCreateTag}
          />
        </FormCard>
        <FormCard customClassName='article-details__card article-details__description-card' title='النص'>
          <QEditor
            layout='default'
            imageRole='article:description'
            placeholder='اكتب هنا..'
            value={description}
            setValue={setDescription}
            uploadUrl={`${process.env.REACT_APP_API_URL}images`}
            accessToken={token}
          />
        </FormCard>
      </div>
    </div>
  )
}

export default ArticleDetailsContainer
