import React, { useCallback, useMemo, useState } from 'react'
import { gql } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { TrashIcon, PencilSimpleIcon, Button } from '@qesheq/qesheq-ui'
import '@qesheq/qesheq-ui/dist/css/q-editor.styles.css'

import { ActionBar, Card, PageHeader, Pagination, Table } from '../../components'
import { Tag } from '../../types/tag.types'
import { RowCell, TableColumn } from '../../components/table/table.types'

import './tags.styles.css'
import { useListing } from '../../hooks'

const TagsQuery = gql`
  query Tags($sort: SortInput, $pagination: PaginationInput, $filters: TagFiltersInput) {
    tags(sort: $sort, filters: $filters, pagination: $pagination) {
      total
      limit
      skip
      data {
        id
        title
        isPublished
      }
    }
  }
`
const ArchiveTagsQuery = gql`
  mutation archiveTags($ids: [ObjectId!]!) {
    archiveTags(ids: $ids)
  }
`

const columns: TableColumn[] = [
  {
    key: 'title',
    label: 'العنوان',
    type: 'text',
    isTitle: true,
  },
  {
    key: 'isPublished',
    label: 'منشور',
    type: 'check',
  },
]

const TagsContainer = (): JSX.Element => {
  const navigate = useNavigate()

  const {
    data: tags,
    isFetching,
    error,
    page,
    deleteEntries: deleteTags,
    changePage,
    changePageLimit,
  } = useListing<Tag>({
    listingQuery: TagsQuery,
    archiveDocumentMutation: ArchiveTagsQuery,
    gqlDocumentsKey: 'tags',
    gqlArchiveMutationKey: 'archiveTags',
    filters: {},
  })

  const [selectedTags, setSelectedTags] = useState<Set<RowCell['id']>>(new Set())

  const handleSelectAll = (): void => {
    const allIds = tags.data.map((tag: Tag) => tag.id)
    setSelectedTags(new Set(allIds))
  }

  const handleUnselectAll = (): void => {
    setSelectedTags(new Set())
  }

  const handleOnSelect = useCallback((id: RowCell['id'], isSelected: boolean) => {
    setSelectedTags((prevState) => {
      const newSet = new Set(prevState)
      if (isSelected) {
        newSet.add(id)
      } else {
        newSet.delete(id)
      }
      return newSet
    })
  }, [])

  const handleOnBulkDelete = async (): Promise<void> => {
    if (selectedTags.size) {
      await deleteTags(Array.from(selectedTags))
      setSelectedTags(new Set())
    }
  }

  const handleOnDelete = async (id: RowCell['id']): Promise<void> => {
    await deleteTags([id])
  }

  const isActionBarOpen = useMemo(() => !!selectedTags.size, [selectedTags])

  if (error) return <Card>SOMETHING WENT WRONG!</Card>

  return (
    <>
      <PageHeader title='الكلمات الدليلية'>
        <Button type='link' label='أضف كلمة' layout='solid' href='/tag/new' customClassName='page-header__add-item' />
      </PageHeader>
      <Card>
        {tags?.data && (
          <Table
            columns={columns}
            dataSource={tags}
            options={{
              mediaBucket: 'tag',
              onSelect: handleOnSelect,
              selectedRows: selectedTags,
              isLoading: isFetching,
              actions: [
                { title: 'Delete', onClick: handleOnDelete, icon: <TrashIcon />, colorStyle: 'secondary' },
                { title: 'Edit', onClick: (id) => navigate(`/tag/${id}`), icon: <PencilSimpleIcon size={16} /> },
              ],
            }}
          />
        )}
      </Card>
      <Card>
        <Pagination paginatedList={tags} page={page} onPageChange={changePage} onLimitChange={changePageLimit} />
      </Card>
      <ActionBar isOpen={isActionBarOpen}>
        <Button
          type='button'
          label='تحديد الكل'
          layout='outline'
          customClassName='listing-action-button'
          onClick={handleSelectAll}
        />
        <Button
          type='button'
          label='إلغاء تحديد الكل'
          layout='outline'
          colorStyle='secondary'
          customClassName='listing-action-button'
          onClick={handleUnselectAll}
        />
        <Button
          type='button'
          label='حذف'
          layout='solid'
          colorStyle='secondary'
          customClassName='listing-action-button'
          onClick={handleOnBulkDelete}
        />
      </ActionBar>
    </>
  )
}

export default TagsContainer
