import React, { useMemo, useContext, ReactNode, useState } from 'react'
import { ApolloClient } from '@apollo/client'
import { AuthContext, AuthContextInterface } from '../contexts/auth.context'

export interface UserInterface {
  email: string
  username?: string
}
interface AuthProviderInterface {
  children: ReactNode
}
interface AuthPayloadInterface {
  token: string
  expiresIn: number
  expiresAt: Date
  user: UserInterface
}
interface LoginInputsInterface {
  token: string
  expiresIn: number
  persist?: boolean
  user: UserInterface
}

const getStoredToken = (): string | null => {
  if (localStorage.getItem('auth') || sessionStorage.getItem('auth')) {
    const authPayload: AuthPayloadInterface = JSON.parse(
      localStorage.getItem('auth') || sessionStorage.getItem('auth')!
    )

    return authPayload?.token || null
  }
  return null
}

export const AuthProvider = ({ children }: AuthProviderInterface): JSX.Element => {
  const storedToken = getStoredToken()
  const [checkingSession, setCheckingSession] = useState(!!storedToken)
  const [isAuthenticated, setIsAuthenticated] = useState(!!storedToken)
  const [token, setToken] = useState<string | null>(storedToken)
  const [user, setUser] = useState<UserInterface | null>(null)

  const login = ({ token: authToken, expiresIn, persist = false, user: authUser }: LoginInputsInterface): void => {
    const authPayload: Omit<AuthPayloadInterface, 'user'> = {
      token: authToken,
      expiresIn,
      expiresAt: new Date(Date.now() + expiresIn - 10000), // 10 seconds offset
    }

    if (persist) {
      localStorage.setItem('auth', JSON.stringify(authPayload))
    } else {
      sessionStorage.setItem('auth', JSON.stringify(authPayload))
    }

    setIsAuthenticated(true)
    setToken(authToken)
    setUser(authUser)
  }

  const logout = (client: ApolloClient<object>): void => {
    localStorage.removeItem('auth')
    sessionStorage.removeItem('auth')
    setIsAuthenticated(false)
    setToken(null)
    setUser(null)
    client.clearStore()
  }

  // eslint-disable-next-line arrow-body-style
  const state: AuthContextInterface = useMemo(() => {
    return {
      checkingSession,
      isAuthenticated,
      user,
      token,
      login,
      logout,
      setUser,
      setCheckingSession,
    }
  }, [checkingSession, isAuthenticated, token, login, logout])

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

export const useAuth = (): AuthContextInterface => useContext(AuthContext)
