import { ReactNode } from 'react'
import * as React from 'react'

import './card.styles.css'

interface CardProps {
  customClassName?: string
  title?: string
  noGap?: boolean
  children: ReactNode
}

const Card = ({ customClassName, title, noGap, children }: CardProps): JSX.Element => (
  <div className={`card ${noGap ? `card--no-gap` : ''} ${customClassName || ''}`}>
    {title && <h2 className='card__title'>{title}</h2>}
    {children}
  </div>
)

export default Card
