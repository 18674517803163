import { gql, useLazyQuery } from '@apollo/client'
import { Spinner, Button, InputField } from '@qesheq/qesheq-ui'
import React, { useState } from 'react'
import { Checkbox } from '../../components'
import { useInputState } from '../../hooks'
import { useAuth } from '../../hooks/auth.hook'

import './login.styles.css'

const LoginQuery = gql`
  query Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
      expiresIn
      user {
        email
        username
      }
    }
  }
`

const LoginContainer = (): JSX.Element => {
  const {
    value: email,
    setValue: setEmail,
    onBlur: onEmailBlur,
    error: emailError,
  } = useInputState({
    initialValue: '',
    validation: {
      required: true,
      email: true,
    },
  })
  const {
    value: password,
    setValue: setPassword,
    onBlur: onPasswordBlur,
    error: passwordError,
  } = useInputState({
    initialValue: '',
    validation: {
      required: true,
      min: 8,
      max: 24,
    },
  })

  const [isLoading, setIsLoading] = useState(false)
  const [shouldBeRemembered, setShouldBeRemembered] = useState(false)
  const [errorMessge, setErrorMessage] = useState('')

  const { login } = useAuth()
  const [loginUser] = useLazyQuery(LoginQuery)

  const handleRememberMe = (): void => {
    setShouldBeRemembered(!shouldBeRemembered)
  }
  const handleSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()

    setErrorMessage('')

    if (emailError || passwordError) {
      setErrorMessage('يرجى تصحيح جميع الحقول للمتابعة')
      return
    }

    setIsLoading(true)

    const { error, data } = await loginUser({
      variables: {
        email,
        password,
      },
    })

    setIsLoading(false)

    if (error) {
      setErrorMessage(error.message)
    }

    if (data?.login) {
      login({
        token: data?.login.accessToken,
        expiresIn: data?.login.expiresIn,
        persist: shouldBeRemembered,
        user: data?.login.user,
      })
    }
  }

  return (
    <form className='login-form' onSubmit={handleSubmit}>
      <InputField
        label='بريدك الالكتروني'
        placeholder='you@email.com'
        customClassName='login-form__input-field'
        value={email}
        onChange={setEmail}
        isDisabled={isLoading}
        onBlur={onEmailBlur}
        errorMessage={emailError}
      />
      <InputField
        label='كلمة السر'
        placeholder='enter password'
        type='password'
        customClassName='login-form__input-field'
        value={password}
        onChange={setPassword}
        isDisabled={isLoading}
        onBlur={onPasswordBlur}
        errorMessage={passwordError}
      />
      <Checkbox
        label='تذكرني'
        onChange={handleRememberMe}
        isChecked={shouldBeRemembered}
        customClassName='login-form__remember-me'
        isDisabled={isLoading}
        layout='compact'
      />
      <div className='login-form__submit-container'>
        <Spinner
          layout='qesheq-default'
          customClassName={`login-form__spinner${isLoading ? ' login-form__spinner--loading' : ''}`}
        />
        <Button
          label='ادخل'
          type='input'
          customClassName={`login-form__submit${isLoading ? ' login-form__submit--loading' : ''}`}
        />
      </div>
      {errorMessge && <span className='login-form__error-message'>{errorMessge}</span>}
    </form>
  )
}

export default LoginContainer
