import { gql } from '@apollo/client'

export const UserByTokenQuery = gql`
  query userByToken($token: String!) {
    userByToken(token: $token) {
      email
      username
    }
  }
`
