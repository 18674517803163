import { ApolloError, NetworkStatus, TypedDocumentNode, useMutation, useQuery } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { RowCell } from '../components/table/table.types'
import { PaginatedList } from '../providers/graphql/types/paginaton.types'

const DEFAULT_PAGE_LIMIT = 20

interface ListingHookInterface<T> {
  data: PaginatedList<T>
  isFetching: boolean
  error?: ApolloError
  page: number
  deleteEntries: (ids: Array<RowCell['id']>) => Promise<void>
  changePage: (page: number) => void
  changePageLimit: (limit: number) => void
}

interface ListingHookProps {
  listingQuery: TypedDocumentNode
  archiveDocumentMutation: TypedDocumentNode
  gqlDocumentsKey: string
  gqlArchiveMutationKey: string
  filters?: { [key: string]: any }
}

const useListing = <T>(props: ListingHookProps): ListingHookInterface<T> => {
  const { listingQuery, archiveDocumentMutation, gqlDocumentsKey, gqlArchiveMutationKey, filters } = props

  const [searchParams, setSearchParams] = useSearchParams()
  const initialPaginatedList: PaginatedList<T> = {
    data: [],
    limit: 20,
    skip: 0,
    total: 0,
  }
  const initialPage = parseInt(searchParams.get('page') ?? '1', 10) - 1 // Page state starts at 0
  const initialPageLimit = searchParams.get('limit') ? parseInt(searchParams.get('limit')!, 10) : DEFAULT_PAGE_LIMIT

  const [paginatedList, setPaginatedList] = useState<PaginatedList<T>>(initialPaginatedList)
  const [page, setPage] = useState<number>(initialPage)
  const [pageLimit, setPageLimit] = useState(initialPageLimit)
  const [isFetching, setIsFetching] = useState(false)

  const { loading, error, data, refetch, networkStatus, fetchMore } = useQuery(listingQuery, {
    variables: {
      sort: { by: 'dateCreated', direction: 'desc' },
      filters,
      pagination: { skip: page * pageLimit, limit: pageLimit },
    },
    notifyOnNetworkStatusChange: true,
  })

  const [archiveEntries] = useMutation(archiveDocumentMutation)

  useEffect(() => {
    if (data?.[gqlDocumentsKey]) {
      setPaginatedList(data[gqlDocumentsKey])
    }
  }, [data?.[gqlDocumentsKey]])

  useEffect(() => {
    fetchMore({
      updateQuery: (_previousList, { fetchMoreResult }) => {
        if (fetchMoreResult) {
          setPaginatedList(fetchMoreResult[gqlDocumentsKey])
        }
        return { ...fetchMoreResult }
      },
      variables: {
        pagination: { skip: page * pageLimit, limit: pageLimit },
      },
    })
    setSearchParams({ page: (page + 1).toString() })
  }, [page, pageLimit])

  useEffect(() => {
    setIsFetching(loading || networkStatus === NetworkStatus.refetch)
  }, [networkStatus, loading])

  const deleteEntries = useCallback(
    async (ids: RowCell['id'][]): Promise<void> => {
      const { data: deleteResult, errors } = await archiveEntries({ variables: { ids } })
      if (deleteResult?.[gqlArchiveMutationKey] && !errors) {
        refetch()
      }
    },
    [archiveEntries, refetch]
  )

  const handlePageChange = useCallback(
    (newPage: number): void => {
      setPage(newPage)
    },
    [setPage]
  )

  const handlePageLimitChange = useCallback(
    (newLimit: number): void => {
      setPageLimit(newLimit)
    },
    [setPageLimit]
  )

  return {
    data: paginatedList,
    isFetching,
    error,
    page,
    deleteEntries,
    changePage: handlePageChange,
    changePageLimit: handlePageLimitChange,
  }
}

export default useListing
