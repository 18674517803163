import React from 'react'
import { Outlet } from 'react-router-dom'
import { Card } from '../../components'
import './auth-page.styles.css'

const AuthPage = (): JSX.Element => (
  <div className='auth-layout'>
    <Card customClassName='auth-layout__card'>
      <img
        src={`${process.env.PUBLIC_URL}/images/logo-black-160x69.svg`}
        alt="Basil - Qesheq's editorial"
        className='auth-layout__logo'
      />
      <Outlet />
    </Card>
  </div>
)

export default AuthPage
